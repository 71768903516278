import { Loading } from 'components';
import { CustomAppBar } from 'components/CustomAppBar';
import { useAuth } from 'hooks';
import { Navigate, Outlet } from 'react-router-dom';

export const AdminHeader = () => (
  <CustomAppBar title="管理画面" topHref="/clients" />
);

export const RootLayout = () => {
  const { currentUser } = useAuth();

  if (currentUser === undefined) {
    return (
      <>
        <AdminHeader />
        <Loading />
      </>
    );
  }
  if (currentUser === null) {
    return <Navigate to="/signin" replace />;
  }
  return (
    <>
      <AdminHeader />
      <Outlet />
    </>
  );
};
