import { AuthError, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from 'lib/firebase';
import { useState } from 'react';

export const useResetPassword = () => {
  const [email, setEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [sending, setSending] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const onChange = (value: string) => {
    setEmail(value);
    setErrorMessage('');
  };

  const sendResetEmail = async () => {
    try {
      setSending(true);
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
    } catch (e) {
      const errorCode = (e as AuthError).code;
      switch (errorCode) {
        case 'auth/invalid-email':
          setErrorMessage('不正なメールアドレスです');
          break;
        case 'auth/user-not-found':
          setErrorMessage('ユーザーが見つかりません');
          break;
        default:
          setErrorMessage('問題が発生しました');
          break;
      }
    } finally {
      setSending(false);
    }
  };
  return { email, sending, success, onChange, sendResetEmail, errorMessage };
};
