import { useAppSelector } from 'app/hooks';
import { Loading, ReportPresentation } from 'components';
import {
  selectClient,
  selectClientAssets,
  selectClientStatus,
  selectClienttHistoricalAggregationByAssetTypeMonthly,
} from 'features';
import { useExchangeRates } from 'hooks';

export const AdminReportPage = () => {
  const client = useAppSelector(selectClient);
  const clientStatus = useAppSelector(selectClientStatus);
  const clientHistoricalAggregationByAssetTypeMonthly = useAppSelector(
    selectClienttHistoricalAggregationByAssetTypeMonthly
  );
  const clientAssets = useAppSelector(selectClientAssets);

  const exchangeRates = useExchangeRates();
  if (
    clientStatus === 'idle' ||
    clientStatus === 'pending' ||
    client === null
  ) {
    return <Loading />;
  }
  return (
    <ReportPresentation
      clientOrFamily={client}
      assets={clientAssets}
      barData={clientHistoricalAggregationByAssetTypeMonthly ?? []}
      exchangeRates={exchangeRates}
    />
  );
};
