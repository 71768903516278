import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ClientAssetHistoryAggregationByAssetType,
  ClientPayload,
  DraftClient,
  FinancialSummary,
  IAsset,
  IManualInput,
  NewApiClient,
} from 'types';
import type { RootState } from '../../app/store';
import { createAppAsyncThunk } from '../../app/withTypes';
import { deleteClientById, getToken } from 'api';
import {
  addClient,
  deleteAsset,
  getAssetsByClientId,
  getClientAssetHistoryAggregationByAssetType,
  getClientById,
  getClientFinancialSummary,
  getClients,
  postAsset,
  putAsset,
  putClientById,
} from 'api';

type ClientsState = {
  clients: NewApiClient[];
  clientsStatus: 'idle' | 'pending' | 'succeeded' | 'rejected';
  client: NewApiClient | null;
  status: 'idle' | 'pending' | 'succeeded' | 'rejected';
  error: string | null;
  assets: IAsset[];
  asset: IAsset | null;
  summary: FinancialSummary | null;
  historicalAggregationByAssetTypeMonthly: ClientAssetHistoryAggregationByAssetType[];
};

const initialState: ClientsState = {
  clients: [],
  clientsStatus: 'idle',
  client: null,
  status: 'idle',
  error: null,
  assets: [],
  asset: null,
  summary: null,
  historicalAggregationByAssetTypeMonthly: [],
};

export const fetchClientsAsync = createAppAsyncThunk(
  'client/fetchClients',
  async (params?: { family_id: string }) => {
    const token = await getToken();
    const response = await getClients({ token, familyId: params?.family_id });
    return response;
  }
);

export const fetchClientByIdAsync = createAppAsyncThunk(
  'client/fetchClientById',
  async ({ id }: { id: string }) => {
    const token = await getToken();
    const twoYearsAgo = new Date(
      new Date().setFullYear(new Date().getFullYear() - 2)
    ).toISOString();
    const today = new Date().toISOString();
    const [client, assets, summary, historicalAggregationByAssetType] =
      await Promise.all([
        getClientById({ id, token }),
        getAssetsByClientId({ clientId: id, token }),
        getClientFinancialSummary({ id, token }),
        getClientAssetHistoryAggregationByAssetType({
          id: id,
          token: token,
          freq: 'monthly',
          start_at: twoYearsAgo,
          end_at: today,
        }),
      ]);
    return {
      client,
      assets,
      summary,
      historicalAggregationByAssetType,
    };
  }
);

export const createClientAsync = createAppAsyncThunk(
  'client/createClient',
  async (payload: ClientPayload) => {
    const token = await getToken();
    const client = await addClient({ token, payload });
    return client;
  }
);

export const replaceClientByIdAsync = createAppAsyncThunk(
  'client/replaceClientById',
  async ({ id, data }: { id: string; data: DraftClient }) => {
    const token = await getToken();
    const response = await putClientById({ id, data, token });
    return response;
  }
);

export const removeClientByIdAsync = createAppAsyncThunk(
  'client/deleteClientById',
  async ({ id }: { id: string }) => {
    const token = await getToken();
    const response = await deleteClientById({ id, token });
    return response;
  }
);

export const fetchAssetsByClientIdAsync = createAppAsyncThunk(
  'client/fetchAssetsByClientId',
  async ({ id }: { id: string }) => {
    const token = await getToken();
    const response = await getAssetsByClientId({ clientId: id, token });
    return response;
  }
);

export const createAssetAsync = createAppAsyncThunk(
  'client/createAsset',
  async ({ client_id, data }: { client_id: string; data: IManualInput }) => {
    const token = await getToken();
    const newAsset = await postAsset({ clientId: client_id, data, token });
    return newAsset;
  }
);

export const replaceAssetAsync = createAppAsyncThunk(
  'client/replaceAsset',
  async ({
    client_id,
    id,
    data,
  }: {
    client_id: string;
    id: string;
    data: IManualInput;
  }) => {
    const token = await getToken();
    const response = await putAsset({ clientId: client_id, id, data, token });
    return response;
  }
);

export const removeAssetAsync = createAppAsyncThunk(
  'client/deleteAsset',
  async ({ client_id, id }: { client_id: string; id: string }) => {
    const token = await getToken();
    const response = await deleteAsset({ client_id: client_id, id, token });
    return response;
  }
);

export const clientSlice = createSlice({
  name: 'client',
  initialState: initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
    deselectClient(state) {
      return {
        ...initialState,
        clients: state.clients,
      };
    },
    dismissError(state) {
      state.error = null;
      state.status = 'succeeded';
    },
    selectAsset(state, action: PayloadAction<IAsset>) {
      state.asset = action.payload;
    },
    deselectAsset(state) {
      state.asset = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchClientsAsync.pending, (state, action) => {
        state.clientsStatus = 'pending';
        state.error = null;
      })
      .addCase(fetchClientsAsync.fulfilled, (state, action) => {
        state.clientsStatus = 'succeeded';
        state.clients = action.payload;
      })
      .addCase(fetchClientsAsync.rejected, (state, action) => {
        state.clientsStatus = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(fetchClientByIdAsync.pending, (state, action) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(fetchClientByIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.client = action.payload.client;
        state.assets = action.payload.assets;
        state.summary = action.payload.summary;
        state.historicalAggregationByAssetTypeMonthly =
          action.payload.historicalAggregationByAssetType;
      })
      .addCase(fetchClientByIdAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(createClientAsync.pending, (state, action) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(createClientAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clients = [action.payload, ...state.clients];
      })
      .addCase(createClientAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(replaceClientByIdAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(replaceClientByIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const id = action.meta.arg.id;
        const idx = state.clients.findIndex(client => client.id === id);
        state.clients = [
          ...state.clients.slice(0, idx),
          action.payload,
          ...state.clients.slice(idx + 1),
        ];
      })
      .addCase(replaceClientByIdAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(removeClientByIdAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(removeClientByIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const ix = state.clients.findIndex(
          client => client.id === action.meta.arg.id
        );
        state.clients = [
          ...state.clients.slice(0, ix),
          ...state.clients.slice(ix + 1),
        ];
      })
      .addCase(removeClientByIdAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(fetchAssetsByClientIdAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(fetchAssetsByClientIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(createAssetAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(createAssetAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.assets = [action.payload, ...state.assets];
      })
      .addCase(createAssetAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(replaceAssetAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(replaceAssetAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const idx = state.assets.findIndex(
          asset => asset.id === action.meta.arg.id
        );
        state.assets = [
          ...state.assets.slice(0, idx),
          action.payload,
          ...state.assets.slice(idx + 1),
        ];
      })
      .addCase(replaceAssetAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(removeAssetAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(removeAssetAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.assets = state.assets.filter(
          asset => asset.id !== action.meta.arg.id
        );
      })
      .addCase(removeAssetAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      });
  },
});

export const selectClientsStatus = (state: RootState) =>
  state.client.clientsStatus;
export const selectClients = (state: RootState) => state.client.clients;

export const selectFamilyClients = createSelector(
  [
    (state: RootState) => state.client.clients,
    (state: RootState, family_id: string) => family_id,
  ],
  (clients: NewApiClient[], family_id: string) =>
    clients.filter(client => client.familyId === family_id)
);

export const selectClientStatus = (state: RootState) => state.client.status;
export const selectClient = (state: RootState) => state.client.client;
export const selectClientAssets = (state: RootState) => state.client.assets;
export const selectClientAsset = (state: RootState) => state.client.asset;
export const selectClientError = (state: RootState) => state.client.error;
export const selectClientFinancialSummary = (state: RootState) =>
  state.client.summary;
export const selectClientMaxPortfoliohNumber = (state: RootState) => {
  const clients = state.client.clients;
  const numbers = (clients ?? [{ name: '0' }]).map(client => {
    const match = client.name.match(/^P?(\d{4})$/);
    return match ? parseInt(match[1], 10) : 0;
  });
  return Math.max(...numbers);
};

export const selectClienttHistoricalAggregationByAssetTypeMonthly = (
  state: RootState
) => state.client.historicalAggregationByAssetTypeMonthly;
