import React from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { WithServerManagedValuesFamily } from 'types/family';

interface FamiliesTableProps {
  families: WithServerManagedValuesFamily[];
  selectedFamily: WithServerManagedValuesFamily | null;
  onSelectionChangeRequest: (target: WithServerManagedValuesFamily) => void;
}

export const FamiliesTable = ({
  families,
  selectedFamily,
  onSelectionChangeRequest,
}: FamiliesTableProps) => {
  return (
    <TableContainer sx={{ marginTop: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width={50}></TableCell>
            <TableCell>ファミリー</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {families.map(family => {
            return (
              <TableRow key={family.id}>
                <TableCell>
                  <Checkbox
                    color="secondary"
                    checked={
                      selectedFamily !== null && family.id === selectedFamily.id
                    }
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': family.name }}
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      event.preventDefault();
                      onSelectionChangeRequest(family);
                    }}
                  />
                </TableCell>
                <TableCell>{family.name}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
