import React, { useEffect, useState } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from 'lib/firebase';
import { useAdminInvitee } from '../hooks/useAdminInvitee';
import { AdminInvitation } from './AdminInvitation';
import { Box, Button, TextField, Typography, Divider } from '@mui/material';
import { MainMenuTabs } from 'components';

const Maintenance = () => {
  const [maintenanceInfo, setMaintenanceInfo] = useState<string | null>();
  // getMaintenanceInfo
  useEffect(() => {
    const getMaintenances = async () => {
      try {
        const docRef = doc(db, 'maintenances', 'maintenance');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const _maintenanceInfo = docSnap.data().infoText;
          setMaintenanceInfo(_maintenanceInfo);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.log('Error:', error);
      }
    };
    getMaintenances();
  }, []);

  const handleMaintenanceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMaintenanceInfo(event.target.value);
  };
  const saveChange = () => {
    const updateMaintenances = async () => {
      try {
        const docRef = doc(db, 'maintenances', 'maintenance');
        await updateDoc(docRef, { infoText: maintenanceInfo });
        // TODO: Feedback success
      } catch (error) {
        console.log('Error:', error);
        // TODO: Feedback error
      }
    };
    updateMaintenances();
  };
  return (
    <>
      <Typography fontWeight="bold" sx={{ mb: 2 }}>
        メンテナンス情報更新
      </Typography>
      <TextField
        size="small"
        multiline
        minRows={4}
        fullWidth
        sx={{ maxWidth: '700px' }}
        value={maintenanceInfo}
        onChange={handleMaintenanceChange}
      />
      <Box>
        <Button
          color="secondary"
          variant="contained"
          onClick={saveChange}
          sx={{ mt: 1 }}
        >
          変更を保存する
        </Button>
      </Box>
    </>
  );
};

export const AdminSettingsPage = () => {
  const { invitees, addInvitee } = useAdminInvitee();
  return (
    <>
      <MainMenuTabs tabValue="/settings" />
      <Box sx={{ p: 4 }}>
        <Maintenance />
        <Divider sx={{ my: 7 }} />
        <AdminInvitation invitees={invitees || []} addInvitee={addInvitee} />
      </Box>
    </>
  );
};
