import CancelIcon from '@mui/icons-material/Cancel';
import PersonIcon from '@mui/icons-material/Person';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Checkbox, Chip, ChipProps, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientForClientsPage, NewApiClient } from 'types/client';
import './styles.css';

type ClientRowProps = {
  client: ClientForClientsPage;
  checked: boolean;
  inset?: boolean;
  id?: string;
  onSelectionChangeRequest: (target: NewApiClient) => void;
};
// TODO: Highlight newly added row
export const ClientRow = ({
  client,
  checked,
  inset = false,
  id,
  onSelectionChangeRequest,
}: ClientRowProps) => {
  const navigate = useNavigate();

  const handleClientRowClick = () => {
    navigate(`/clients/${client.id}`);
  };

  return (
    <TableRow
      key={id}
      className="client-list-hover"
      onClick={handleClientRowClick}
    >
      <TableCell>
        <Checkbox
          color="secondary"
          checked={checked}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': client.name }}
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.preventDefault();
            event.stopPropagation();
            const { familyName, mentorName, ...filteredClient } = client;
            onSelectionChangeRequest(filteredClient);
          }}
        />
      </TableCell>
      <TableCell>{client.name}</TableCell>
      <TableCell>{client.familyName}</TableCell>
      <TableCell>{client.mentorName}</TableCell>
      <TableCell>{client.clientBillingId || ''}</TableCell>
      <TableCell>
        {client.manuallyUpdatedAt
          ? new Date(client.manuallyUpdatedAt).toLocaleString('ja-JP', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            })
          : ''}
      </TableCell>
      <TableCell align="center">
        <Chip
          label={client.status}
          size="small"
          icon={
            {
              顧客: <PersonIcon />,
              リード: <TrendingUpIcon />,
              デモ: <SlideshowIcon />,
              解約済み: <CancelIcon />,
            }[client.status as string] as ChipProps['icon']
          }
          color={
            {
              顧客: 'success',
              リード: 'warning',
              デモ: 'info',
              解約済み: 'error',
            }[client.status as string] as ChipProps['color']
          }
        />
      </TableCell>
      <TableCell>{client.note ?? ''}</TableCell>
    </TableRow>
  );
};
