import { doc, getDoc } from 'firebase/firestore';
import { db } from 'lib/firebase';
import { useEffect, useState } from 'react';

export const useMaintenance = () => {
  const [maintenanceInfo, setMaintenanceInfo] = useState<string>('');
  useEffect(() => {
    const getMaintenances = async () => {
      try {
        const docRef = doc(db, 'maintenances', 'maintenance');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const _maintenanceInfo = docSnap.data().infoText;
          setMaintenanceInfo(_maintenanceInfo);
        } else {
          console.log('No such document!');
        }
      } catch (_error) {
        const error = _error as Error;
        console.error(error.toString());
      }
    };
    getMaintenances();
  }, []);
  return maintenanceInfo;
};
