import { useState } from 'react';
import {
  Dialog,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Family } from 'types';

interface CreateFamilyDialogProps {
  open: boolean;
  family: Family;
  setFamily: React.Dispatch<React.SetStateAction<Family>>;
  onClose: () => void;
  submit: (family: Family) => void;
  status: 'pending' | 'idle' | 'succeeded' | 'rejected';
  error: string | null;
  dismissError: () => void;
}

export const CreateFamilyDialog = ({
  family,
  open,
  setFamily,
  onClose,
  submit,
  status,
  error,
  dismissError,
}: CreateFamilyDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          submit({
            ...family,
            name: family.name.trim(),
          });
        },
      }}
    >
      <DialogTitle sx={{ mx: 4, mt: 2, mb: 2 }}>
        ファミリーの新規作成
      </DialogTitle>
      <DialogContent sx={{ mx: 4 }}>
        <div>
          <TextField
            type="text"
            label="ファミリー名"
            value={family.name}
            autoFocus
            autoComplete="off"
            sx={{ my: 2 }}
            variant="standard"
            onChange={event => {
              setFamily(prev => ({
                ...prev,
                name: event.target.value,
              }));
              dismissError();
            }}
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ mr: 4, mb: 1 }}>
        <Button variant="outlined" onClick={onClose}>
          キャンセル
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => submit({ ...family, name: family.name.trim() })}
          disabled={status === 'pending' || family.name.trim().length === 0}
        >
          {status === 'pending' ? '作成中...' : '作成'}
        </Button>
      </DialogActions>
      {status === 'rejected' && (
        <div style={{ textAlign: 'right', margin: 12, color: 'red' }}>
          {error}
        </div>
      )}
    </Dialog>
  );
};
