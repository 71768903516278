import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'components/Snackbar';
import { AppProvider, AuthProvider } from 'contexts';
import { theme } from 'createTheme';
import {
  AdminClientLayout,
  AdminClientsLayout,
  AdminContentLayout,
  AdminFamilyLayout,
  RootLayout,
} from 'layouts';
import {
  AdminClientDashboardPage,
  AdminClientInsightPage,
  AdminFamilyReportPage,
  AdminMentoringPage,
  AdminReportPage,
  AdminSettingsPage,
  AdminSignInPage,
  AssetsPage,
  ClientsPage,
  FamiliesPage,
  InsightsPage,
  ResetPasswordPage,
  RootPage,
  UpdateEmailPage,
  UpdatePasswordPage,
} from 'pages';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SnackbarProvider>
          <AppProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/signin" element={<AdminSignInPage />} />
                <Route path="/" element={<RootLayout />}>
                  <Route index element={<RootPage />} />
                  <Route
                    path="update-password"
                    element={<UpdatePasswordPage />}
                  />
                  <Route
                    path="reset-password"
                    element={<ResetPasswordPage />}
                  />
                  <Route path="update-email" element={<UpdateEmailPage />} />
                  <Route path="/" element={<AdminContentLayout />}>
                    <Route path="insights" element={<InsightsPage />} />
                    <Route path="settings" element={<AdminSettingsPage />} />
                    <Route path="families">
                      <Route index element={<FamiliesPage />} />
                      <Route path=":familyId" element={<AdminFamilyLayout />}>
                        <Route
                          path="report"
                          element={<AdminFamilyReportPage />}
                        />
                      </Route>
                    </Route>
                    <Route path="clients" element={<AdminClientsLayout />}>
                      <Route index element={<ClientsPage />} />
                      <Route path=":clientId" element={<AdminClientLayout />}>
                        <Route index element={<AdminClientDashboardPage />} />
                        <Route
                          path="mentoring"
                          element={<AdminMentoringPage />}
                        />
                        <Route
                          path="insight"
                          element={<AdminClientInsightPage />}
                        />
                        <Route path="assets" element={<AssetsPage />} />
                        <Route path="report" element={<AdminReportPage />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
                <Route path="*" element={<div>404</div>} />
              </Routes>
            </BrowserRouter>
          </AppProvider>
        </SnackbarProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
