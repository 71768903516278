import { useAuth } from 'hooks';
import React, { useState } from 'react';
import {
  updateEmail,
  AuthError,
  EmailAuthProvider,
  reauthenticateWithCredential,
  verifyBeforeUpdateEmail,
  ActionCodeSettings,
} from 'firebase/auth';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from 'lib/firebase';

export const useUpdateEmail = () => {
  const [password, setPassword] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string>('');
  const [errorPasswordMessage, setErrorPasswordMessage] = useState<string>('');
  const [errorEmailMessage, setErrorEmailMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setErrorPasswordMessage('');
    setErrorMessage('');
    setPassword(event.target.value);
  };

  const onNewEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setErrorEmailMessage('');
    setErrorMessage('');
    setNewEmail(event.target.value);
  };

  const reauthenticate = async () => {
    const email = currentUser?.email;
    if (!email) {
      return;
    }
    const credential = await EmailAuthProvider.credential(email, password);

    try {
      await reauthenticateWithCredential(currentUser, credential);
    } catch (e) {
      const errorCode = (e as AuthError).code;
      switch (errorCode) {
        case 'auth/wrong-password':
          setErrorPasswordMessage('パスワードが間違っています');
          break;
        case 'auth/invalid-email':
          setErrorEmailMessage('メールアドレスが不正です。'); // should no come here
          break;
        case 'auth/email-already-in-use':
          setErrorEmailMessage('メールアドレスがすでに使われています。'); // should not come here
          break;
        case 'auth/requires-recent-login':
          setErrorMessage(
            'メールアドレスの変更には最近ログインした記録が必要です。'
          ); // should not come here
          break;
        default:
          setErrorMessage('認証に関して問題が発生しました。');
          break;
      }
      throw e;
    }
  };

  const _update = async () => {
    if (!currentUser) {
      return;
    }
    const tokenResult = await currentUser.getIdTokenResult();
    const _isAdmin = tokenResult.claims.admin as boolean | undefined;

    try {
      // await updateEmail(currentUser, newEmail)
      const actionCodeSettings: ActionCodeSettings = {
        url: `https://portfoli-oh.pragmaworks.co.jp${_isAdmin ? '/admin' : ''}/signin?email=${newEmail}`,
      };
      await verifyBeforeUpdateEmail(currentUser, newEmail);
    } catch (_e) {
      const code = (_e as AuthError).code;
      switch (code) {
        case 'auth/email-already-in-use':
          setErrorEmailMessage('メールアドレスはすでに使用されています');
          break;
        case 'auth/missing-android-pkg-name':
          setErrorMessage('問題が発生しました。');
          break;
        case 'auth/missing-continue-uri':
          setErrorMessage('問題が発生しました。');
          break;
        case 'auth/invalid-continue-uri':
          setErrorMessage('問題が発生しました。');
          break;
        case 'auth/missing-ios-bundle-id':
          setErrorMessage('問題が発生しました。');
          break;
        case 'auth/unauthorized-continue-uri':
          setErrorMessage('問題が発生しました。');
          break;
      }
      throw _e;
    }
    return;
  };

  const update = async () => {
    setErrorEmailMessage('');
    setIsLoading(true);
    try {
      await reauthenticate();
      await _update();
      setSuccess(true);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  return {
    password,
    newEmail,
    errorMessage,
    errorEmailMessage,
    errorPasswordMessage,
    success,
    update,
    onPasswordChange,
    onNewEmailChange,
    isLoading,
  } as const;
};
