/**
 * ファイルを署名付きURLにアップロードする関数
 * 
 * Abortの利用例
 * 
const controller = new AbortController();
const { signal } = controller;

uploadFileWithXHR(file, url, (progress) => {
  console.log(`Progress: ${progress}%`);
}, signal)
  .then(() => {
    console.log('Upload successful');
  })
  .catch((error) => {
    if (error.name === 'AbortError') {
      console.log('Upload canceled');
    } else {
      console.error('Upload failed:', error.message);
    }
  });

// キャンセルしたい場合
// 例えば、ユーザーが「キャンセル」ボタンをクリックしたとき
document.getElementById('cancelButton')?.addEventListener('click', () => {
  controller.abort();
});
 *
 * @param file - アップロードするファイル
 * @param url - 署名付きURL
 * @param onProgress - 進捗状況を受け取るコールバック
 * @returns アップロード完了時に解決されるPromise
 */
export async function uploadFileWithXHR({
  file,
  url,
  onprogress,
  signal,
}: {
  file: File;
  url: string;
  onprogress: (e: ProgressEvent<EventTarget>) => void;
  signal?: AbortSignal;
}): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    xhr.setRequestHeader(
      'Content-Type',
      file.type || 'application/octet-stream'
    );

    // 進捗状況のハンドリング
    xhr.upload.onprogress = onprogress;

    // 成功時のハンドリング
    xhr.onload = () => {
      if (xhr.status === 200 || xhr.status === 201) {
        resolve();
      } else {
        reject(
          new Error(
            `Upload failed with status: ${xhr.status} ${xhr.statusText}`
          )
        );
      }
    };

    // エラー時のハンドリング
    xhr.onerror = () => {
      reject(new Error('Upload failed due to a network error'));
    };

    // アボート時のハンドリング
    xhr.onabort = () => {
      reject(new Error('Upload aborted'));
    };

    if (signal) {
      signal.addEventListener('abort', () => {
        xhr.abort();
      });
    }

    xhr.send(file);
  });
}
