import { FinancialProductDetail, FinancialProductListITem } from 'types';
import { API_URL } from 'config';

export const getFinancialProducts = async (token: string) => {
  const response = await fetch(`${API_URL}/financial-products`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<FinancialProductListITem[]>;
};

export const getFinancialProductDetail = async (token: string, id: string) => {
  const response = await fetch(`${API_URL}/financial-products/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<FinancialProductDetail>;
};
