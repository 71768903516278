import { useState } from 'react';
import {
  Dialog,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { WithServerManagedValuesFamily } from 'types/family';

interface EditFamilyDialogProps {
  open: boolean;
  onClose: () => void;
  family: WithServerManagedValuesFamily;
  onSave: (formFamily: WithServerManagedValuesFamily) => Promise<void>;
  status: 'pending' | 'idle' | 'succeeded' | 'rejected';
  error: string | null;
}

export const EditFamilyDialog = ({
  open,
  onClose,
  family: initialFamily,
  onSave,
  status,
  error,
}: EditFamilyDialogProps) => {
  const [formFamily, setFormFamily] = useState(initialFamily);
  const handleSave = async () => {
    onSave({
      ...formFamily,
      name: formFamily.name.trim(),
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          handleSave();
        },
      }}
    >
      <DialogTitle sx={{ mx: 4, mt: 2, mb: 2 }}>ファミリー名編集</DialogTitle>
      <DialogContent sx={{ mx: 4 }}>
        <div>
          <TextField
            type="text"
            label="ファミリー名"
            value={formFamily.name}
            autoFocus
            autoComplete="off"
            sx={{ my: 2 }}
            variant="standard"
            onChange={event =>
              setFormFamily(prev => ({ ...prev, name: event.target.value }))
            }
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ mr: 4, mb: 1 }}>
        <Button variant="outlined" onClick={onClose}>
          キャンセル
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSave}
          disabled={status === 'pending' || formFamily.name.trim().length === 0}
        >
          {status === 'pending' ? '更新中...' : '編集する'}
        </Button>
      </DialogActions>
      {status === 'rejected' && (
        <div style={{ textAlign: 'right', margin: 12, color: 'red' }}>
          編集できませんでした
        </div>
      )}
    </Dialog>
  );
};
