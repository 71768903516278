import { createSlice } from '@reduxjs/toolkit';
import { getFinancialProductDetail, getFinancialProducts } from 'api';
import { RootState } from 'app/store';
import { createAppAsyncThunk } from 'app/withTypes';
import { getToken } from 'api';
import { FinancialProductDetail, FinancialProductListITem } from 'types';

type FinancialProductState = {
  listStatus: 'idle' | 'pending' | 'succeeded' | 'rejected';
  listError: string | null;
  products: FinancialProductListITem[];

  detailStatus: 'idle' | 'pending' | 'succeeded' | 'rejected';
  detailError: string | null;
  product: FinancialProductDetail | null;
};

const initialState: FinancialProductState = {
  listStatus: 'idle',
  listError: null,
  products: [],

  detailStatus: 'idle',
  detailError: null,
  product: null,
};

/**
 * fetchFinancialProducts is not yet used in this project, but will be used when
 * financial product list page is implemented
 */
export const fetchFinancialProductsAsync = createAppAsyncThunk(
  'financialProduct/fetchFinancialProducts',
  async () => {
    const token = await getToken();
    const response = await getFinancialProducts(token);
    return response;
  }
);

export const fetchFinancialProductDetailAsync = createAppAsyncThunk(
  'financialProduct/fetchFinancialProductDetail',
  async (id: string) => {
    const token = await getToken();
    const response = await getFinancialProductDetail(token, id);
    return response;
  }
);

export const financialProductSlice = createSlice({
  name: 'financialProduct',
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
    deselectProduct(state) {
      state.product = null;
      state.detailStatus = 'idle';
      state.detailError = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchFinancialProductsAsync.pending, state => {
        state.listStatus = 'pending';
      })
      .addCase(fetchFinancialProductsAsync.fulfilled, (state, action) => {
        state.listStatus = 'succeeded';
        state.products = action.payload;
      })
      .addCase(fetchFinancialProductsAsync.rejected, state => {
        state.listStatus = 'rejected';
      })
      .addCase(fetchFinancialProductDetailAsync.pending, state => {
        state.detailStatus = 'pending';
      })
      .addCase(fetchFinancialProductDetailAsync.fulfilled, (state, action) => {
        state.detailStatus = 'succeeded';
        state.product = action.payload;
      })
      .addCase(fetchFinancialProductDetailAsync.rejected, state => {
        state.detailStatus = 'rejected';
        state.detailError = 'エラーが発生しました';
      });
  },
});

export const selectFinancialProductsStatus = (state: RootState) =>
  state.financialProduct.listStatus;
export const selectFinancialProductsError = (state: RootState) =>
  state.financialProduct.listError;
export const selectFinancialProducts = (state: RootState) =>
  state.financialProduct.products;

export const selectFinancialProductStatus = (state: RootState) =>
  state.financialProduct.detailStatus;
export const selectFinancialProductError = (state: RootState) =>
  state.financialProduct.detailError;
export const selectFinancialProduct = (state: RootState) =>
  state.financialProduct.product;
