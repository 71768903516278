import { useState } from 'react';
import {
  Dialog,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { Family } from 'types';

interface DeleteFamilyDialogProps {
  open: boolean;
  family: Family;
  onClose: () => void;
  submit: () => void;
  status: 'pending' | 'idle' | 'succeeded' | 'rejected';
  error: string | null;
}

export const DeleteFamilyDialog = ({
  family,
  open,
  onClose,
  submit,
  status,
  error,
}: DeleteFamilyDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          submit();
        },
      }}
    >
      <DialogTitle sx={{ mx: 4, mt: 2, mb: 2 }}>ファミリーの削除</DialogTitle>
      <DialogContent sx={{ mx: 4 }}>
        <DialogContentText>
          本当にファミリー: {family.name} を削除しますか?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mr: 4, mb: 1 }}>
        <Button variant="outlined" onClick={onClose}>
          キャンセル
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => submit()}
          disabled={status === 'pending'}
        >
          {status === 'pending' ? '削除中...' : '削除'}
        </Button>
      </DialogActions>
      {status === 'rejected' && (
        <div style={{ textAlign: 'right', margin: 12, color: 'red' }}>
          {error}
        </div>
      )}
    </Dialog>
  );
};
