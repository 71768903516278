import React, { useEffect, useState } from 'react';
import { db } from 'lib/firebase';
import {
  collection,
  addDoc,
  query,
  orderBy,
  getDocs,
  QueryDocumentSnapshot,
  SnapshotOptions,
  serverTimestamp,
} from 'firebase/firestore';
import { useFirestoreCollection } from 'hooks';

export const useAdminInvitee = () => {
  // const [invitees, setInvitees] = useState<Invitee[]>([])
  const colRef = collection(db, 'adminInvitees').withConverter<Invitee>(
    inviteeConverter
  );
  const adminInviteeQuery = query(colRef, orderBy('invitedAt', 'desc'));
  const {
    data: invitees,
    isLoading,
    error,
  } = useFirestoreCollection(adminInviteeQuery);

  const addInvitee = async (newInvitee: Invitee) => {
    const alreadyInvited = invitees?.find(
      _invitee => _invitee.email === newInvitee.email
    );
    if (alreadyInvited !== undefined) {
      throw new Error('招待済みです');
    }
    try {
      const docRef = await addDoc(colRef, newInvitee);
      return { status: 'success: ' + docRef.id };
    } catch (e) {
      console.log((e as Error).message);
      return { status: 'error' };
    }
  };
  return { invitees, addInvitee };
};

export class Invitee {
  constructor(
    readonly email: string,
    readonly joined: boolean,
    readonly invitedAt?: Date,
    readonly id?: string
  ) {
    this.id = id;
    this.email = email;
    this.invitedAt = invitedAt;
    this.joined = joined;
  }
  toString() {
    if (!this.invitedAt) {
      return this.email;
    }
    return `${this.email} (${this.invitedAt.toLocaleString('ja-JP')} に招待済み)`;
  }
}

const inviteeConverter = {
  toFirestore: (invitee: Invitee) => {
    const invitedAt = invitee.invitedAt || serverTimestamp();
    const data = {
      email: invitee.email,
      invitedAt: invitedAt,
      joined: false,
    };
    return data;
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions | undefined
  ) => {
    const data = snapshot.data(options);

    return new Invitee(
      data.email,
      data.joined,
      data.invitedAt?.toDate(),
      snapshot.id
    );
  },
};
