import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

type DeleteClientDialogProps = {
  open: boolean;
  onClose: () => void;
  name: string;
  deleteClient: () => Promise<void>;
  status: 'pending' | 'idle' | 'succeeded' | 'rejected';
  error: string | null;
};
export const DeleteClientDialog = ({
  open,
  onClose,
  name,
  deleteClient,
  status,
  error,
}: DeleteClientDialogProps) => (
  <Dialog open={open} onClose={onClose} fullWidth>
    <DialogTitle sx={{ mx: 4, mt: 2, mb: 2 }}>顧客削除</DialogTitle>
    <DialogContent sx={{ mx: 4 }}>
      <DialogContentText>{`本当に「${name}」様を削除しますか？`}</DialogContentText>
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </DialogContent>
    <DialogActions sx={{ justifyContent: 'flex-start', mx: 6, mb: 4 }}>
      <Button variant="outlined" onClick={onClose}>
        キャンセル
      </Button>
      <Button
        variant="contained"
        color="warning"
        disabled={status === 'pending'}
        onClick={() => deleteClient()}
        startIcon={status === 'pending' ? <CircularProgress size={20} /> : null}
      >
        {status === 'pending' ? '削除中...' : '削除'}
      </Button>
    </DialogActions>
  </Dialog>
);
