import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  deleteMentoringSheetFile,
  getMentoringSheetFileById,
  getMentoringSheetFiles,
  postMentoringSheetFile,
  putMentoringSheetFile,
  uploadFileWithXHR,
} from 'api';
import { RootState } from 'app/store';
import { createAppAsyncThunk } from 'app/withTypes';
import { getToken } from 'api';
import {
  MentoringSheetFileWithUploadUrl,
  MentoringSheetFileWithTransferData,
  WithId,
  WithTimestamp,
} from 'types';
import { calculateSHA256Hash } from 'utils';

type MentoringSheetFileState = {
  status: 'idle' | 'pending' | 'succeeded' | 'rejected';
  files: WithTimestamp<WithId<MentoringSheetFileWithTransferData>>[];
  error: string | null;
};

const initialState: MentoringSheetFileState = {
  status: 'idle',
  files: [],
  error: null,
};

export const fetchMentoringSheetFilesAsync = createAppAsyncThunk(
  'client/fetchMentoringSheetFiles',
  async ({
    client_id,
    mentoring_sheet_id,
  }: {
    client_id: string;
    mentoring_sheet_id: string;
  }) => {
    const token = await getToken();
    const response = await getMentoringSheetFiles({
      client_id,
      mentoring_sheet_id,
      token,
    });
    return response;
  }
);

export const fetchMentoringSheetFileAsync = createAppAsyncThunk(
  'client/fetchMentoringSheetFile',
  async ({
    client_id,
    mentoring_sheet_id,
    file_id,
  }: {
    client_id: string;
    mentoring_sheet_id: string;
    file_id: string;
  }) => {
    const token = await getToken();
    const sheetFile = await getMentoringSheetFileById({
      client_id,
      mentoring_sheet_id,
      file_id,
      token,
    });
    return sheetFile;
  }
);

export const removeMentoringSheetFileAsync = createAppAsyncThunk(
  'client/deleteMentoringSheetFile',
  async ({
    client_id,
    mentoring_sheet_id,
    file_id,
  }: {
    client_id: string;
    mentoring_sheet_id: string;
    file_id: string;
  }) => {
    const token = await getToken();
    await deleteMentoringSheetFile({
      client_id,
      mentoring_sheet_id,
      file_id,
      token,
    });
    return;
  }
);

export const uploadMentoringSheetFileAsync = createAppAsyncThunk(
  'client/uploadMentoringSheetFile',
  async (
    {
      client_id,
      mentoring_sheet_id,
      file,
    }: {
      client_id: string;
      mentoring_sheet_id: string;
      file: File;
    },
    { dispatch, rejectWithValue }
  ) => {
    const token = await getToken();
    const arrayBuffer = await file.arrayBuffer();
    const sha256_hash = await calculateSHA256Hash(arrayBuffer);
    const postData = await postMentoringSheetFile({
      client_id,
      mentoring_sheet_id,
      file_name: file.name,
      sha256_hash,
      filesize_bytes: file.size,
      mime_type: file.type,
      status: 'pending',
      uploaded_at: null,
      token,
    });
    // if `POST` throws an error, it will be handled in `extraReducers's .rejected`
    dispatch(mentoringSheetFileSlice.actions.startToUpload(postData));
    try {
      await uploadFileWithXHR({
        file,
        url: postData.upload_url,
        onprogress: (e: ProgressEvent) => {
          dispatch(
            mentoringSheetFileSlice.actions.updateProgress({
              file_id: postData.id,
              progress: e.loaded / e.total,
            })
          );
        },
      });
      const data = await putMentoringSheetFile({
        client_id: client_id,
        mentoring_sheet_id: mentoring_sheet_id,
        file_id: postData.id,
        file_name: file.name,
        sha256_hash,
        filesize_bytes: file.size,
        mime_type: file.type,
        status: 'uploaded',
        uploaded_at: new Date().toISOString(),
        token,
      });
      return data;
    } catch (err) {
      dispatch(
        mentoringSheetFileSlice.actions.updateError({
          file_id: postData.id,
          error: err instanceof Error ? err.message : 'エラーが発生しました',
        })
      );
      await deleteMentoringSheetFile({
        client_id,
        mentoring_sheet_id,
        file_id: postData.id,
        token,
      });
      throw err;
    }
  }
);

export const mentoringSheetFileSlice = createSlice({
  name: 'mentoringSheetFile',
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
    startToUpload(
      state,
      action: PayloadAction<
        WithTimestamp<WithId<MentoringSheetFileWithUploadUrl>>
      >
    ) {
      const ix = state.files.findIndex(file => file.id === action.payload.id);
      const newData = {
        ...action.payload,
        upload_progress: 0,
        error: null,
      } as const;

      if (ix !== -1) {
        state.files[ix] = {
          ...state.files[ix],
          ...newData,
        };
      } else {
        state.files = [...state.files, newData];
      }
    },
    updateProgress(
      state,
      action: PayloadAction<{ file_id: string; progress: number }>
    ) {
      state.files = state.files.map(file => {
        if (file.id === action.payload.file_id) {
          return {
            ...file,
            upload_progress: action.payload.progress,
          };
        }
        return file;
      });
    },
    updateError(
      state,
      action: PayloadAction<{ file_id: string; error: string }>
    ) {
      const ix = state.files.findIndex(
        file => file.id === action.payload.file_id
      );
      if (ix === -1) {
        return;
      }

      state.files[ix] = {
        ...state.files[ix],
        error: action.payload.error,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMentoringSheetFilesAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(fetchMentoringSheetFilesAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.files = action.payload.map(f => ({
          ...f,
          upload_progress: null,
          error: null,
        }));
      })
      .addCase(fetchMentoringSheetFilesAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(uploadMentoringSheetFileAsync.pending, (state, action) => {
        // handled in thunk
      })
      .addCase(uploadMentoringSheetFileAsync.fulfilled, (state, action) => {
        const ix = state.files.findIndex(file => file.id === action.payload.id);
        state.files = [
          ...state.files.slice(0, ix),
          {
            ...action.payload,
            upload_progress: null,
            error: null,
          },
          ...state.files.slice(ix + 1),
        ];
      })
      .addCase(uploadMentoringSheetFileAsync.rejected, (state, action) => {
        state.error = 'エラーが発生しました';
      })
      .addCase(removeMentoringSheetFileAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(removeMentoringSheetFileAsync.fulfilled, (state, action) => {
        const ix = state.files.findIndex(
          file => file.id === action.meta.arg.file_id
        );
        state.files = [
          ...state.files.slice(0, ix),
          ...state.files.slice(ix + 1),
        ];
      })
      .addCase(removeMentoringSheetFileAsync.rejected, (state, action) => {
        const ix = state.files.findIndex(
          file => file.id === action.meta.arg.file_id
        );
        state.files[ix].error = action.error.message ?? 'エラーが発生しました';
      });
  },
});

export const selectMentoringSheetFileStatus = (state: RootState) =>
  state.mentoringSheetFile.status;

export const selectMentoringSheetFileError = (state: RootState) =>
  state.mentoringSheetFile.error;

export const selectMentoringSheetFiles = (state: RootState) =>
  state.mentoringSheetFile.files;
