import React, { useState, useEffect, createContext, ReactNode } from 'react';
import { auth } from '../lib/firebase';
import { User, UserCredential, signInWithCustomToken } from 'firebase/auth';
import { getCustomToken, getTokenWithEmailOrNameAndPassword } from 'api';

export type AuthContextValue = {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  currentUser: User | null | undefined;
  signIn: (
    emailOrName: string,
    password: string
  ) => Promise<UserCredential | void>;
  signOut: () => Promise<void>;
};

const AuthContext = createContext<AuthContextValue>({
  status: 'idle',
  error: null,
  currentUser: null,
  signIn: (() => {}) as any,
  signOut: () => Promise.resolve(),
});

const useOnAuthStateChanged = () => {
  const [currentUser, setCurrentUser] = useState<User | null | undefined>(
    undefined
  );
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);
  return currentUser;
};

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'succeeded' | 'failed'
  >('idle');
  const [error, setError] = useState<string | null>(null);
  const currentUser = useOnAuthStateChanged();
  const signIn = async (emailOrName: string, password: string) => {
    setError(null);
    setStatus('loading');
    try {
      const { idToken } = await getTokenWithEmailOrNameAndPassword(
        emailOrName,
        password
      );
      const { custom_token } = await getCustomToken(idToken);
      const userCredential = await signInWithCustomToken(auth, custom_token);
      userCredential.user.getIdTokenResult().then(result => {
        const isAdmin = result.claims.admin as boolean | undefined;
        if (!isAdmin) {
          signOut();
          throw new Error('ログインできるのは管理者のみです');
        }
      });
      setStatus('succeeded');
      return userCredential;
    } catch (err) {
      setError('ログインに失敗しました');
      setStatus('failed');
      console.error(err);
      throw err;
    }
  };

  const signOut = async () => {
    await auth.signOut();
  };

  return (
    <AuthContext.Provider
      value={{ status, error, currentUser, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
