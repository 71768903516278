import { Box, Typography } from '@mui/material';
import { getExcelBlobForClient, getToken } from 'api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  AssetFilter,
  AssetTable,
  AssetTableToggle,
  FinancialProductDetailDialog,
  Loading,
  useAssetTableSorter,
} from 'components';
import { useAppContext } from 'contexts';
import {
  clientSlice,
  createAssetAsync,
  fetchFinancialProductDetailAsync,
  fetchFinancialProductsAsync,
  financialProductSlice,
  removeAssetAsync,
  replaceAssetAsync,
  selectClient,
  selectClientAsset,
  selectClientAssets,
  selectClientError,
  selectClientStatus,
  selectFinancialProduct,
  selectFinancialProductError,
  selectFinancialProducts,
  selectFinancialProductsError,
  selectFinancialProductsStatus,
  selectFinancialProductStatus,
} from 'features';
import { filterAssets, useAssetFilter, useSnackbar, useToggle } from 'hooks';
import React, { useEffect } from 'react';
import { AssetAdditionDialog } from '../components/Asset/AssetAdditionDialog';
import { AssetMenu } from '../components/Asset/AssetMenu';
import { DelProductDialog } from '../components/Asset/DelProductDialog';

export const AssetsPage = () => {
  const [downloadExcelStatus, setDownloadExcelStatus] = React.useState<
    'idle' | 'pending' | 'succeeded' | 'rejected'
  >('idle');
  const [downloadExcelErrorMessage, setDownloadExcelErrorMessage] =
    React.useState<string | null>(null);
  const dispatch = useAppDispatch();
  const client = useAppSelector(selectClient);
  const clientStatus = useAppSelector(selectClientStatus);
  const clientError = useAppSelector(selectClientError);
  const clientAsset = useAppSelector(selectClientAsset);
  const clientAssets = useAppSelector(selectClientAssets);

  const financialProductsStatus = useAppSelector(selectFinancialProductsStatus);
  const financialProductsError = useAppSelector(selectFinancialProductsError);
  const financialProducts = useAppSelector(selectFinancialProducts);

  const financialProductStatus = useAppSelector(selectFinancialProductStatus);
  const financialProductError = useAppSelector(selectFinancialProductError);
  const financialProduct = useAppSelector(selectFinancialProduct);

  const [detailVisible, toggleDetailVisible] = useToggle(false);
  const [soldVisible, toggleSoldVisible] = useToggle(false);
  const { order, orderBy, buildSorter } = useAssetTableSorter();
  const { draftAsset, setDraftAsset } = useAppContext();
  const [openAddProduct, toggleOpenAddProduct] = useToggle(false);
  const [openDelProduct, toggleOpenDelProduct] = useToggle(false);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [filterOptions, setFilterOptions] = useAssetFilter();

  const showAddSuccessSnackbar = React.useCallback(() => {
    showSuccessSnackbar(`追加しました`);
  }, []);
  const showEditSuccessSnackbar = React.useCallback(() => {
    showSuccessSnackbar(`編集しました`);
  }, []);
  const showAssetErrorSnackbar = React.useCallback(() => {
    showErrorSnackbar('エラーが発生しました。');
  }, []);

  const filterdAssets = filterAssets(clientAssets, filterOptions);

  useEffect(() => {
    // fetch financial products once
    if (financialProductsStatus === 'idle') {
      dispatch(fetchFinancialProductsAsync());
    }
  }, [dispatch, financialProductsStatus]);

  if (!client) {
    return <Loading />;
  }

  return (
    <>
      <Box sx={{ px: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
          <AssetMenu
            selectedAsset={draftAsset}
            toggleOpenAddProduct={toggleOpenAddProduct}
            toggleOpenDeleteProduct={toggleOpenDelProduct}
            downloadExcel={() => {
              (async () => {
                setDownloadExcelStatus('pending');
                try {
                  const token = await getToken();
                  const blob = await getExcelBlobForClient({
                    client_id: client.id,
                    token,
                    order,
                    orderBy,
                  });
                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = `ポートフォリ王-銘柄一覧-${client?.name ? client.name + '-' : ''}${new Date().toJSON().slice(0, 10)}.xlsx`;
                  document.body.appendChild(a); // Append the element to the DOM -> required for Firefox
                  a.click();
                  a.remove();
                  setDownloadExcelStatus('succeeded');
                } catch (error) {
                  setDownloadExcelStatus('rejected');
                  setDownloadExcelErrorMessage(
                    'エクセルのダウンロードに失敗しました'
                  );
                }
              })();
            }}
            downloadExcelStatus={downloadExcelStatus}
            downloadExcelErrorMessage={downloadExcelErrorMessage}
          />
          <AssetFilter
            allRows={filterdAssets}
            setTableFilterOptions={setFilterOptions}
          />
          <AssetTableToggle
            toggleDetailVisible={toggleDetailVisible}
            toggleSoldVisible={toggleSoldVisible}
          />
        </Box>
        {clientStatus === 'pending' ? (
          <Loading />
        ) : clientStatus === 'rejected' ? (
          clientError
        ) : (
          <>
            {financialProductsError && (
              <Typography>{financialProductsError}</Typography>
            )}
            <AssetTable
              selectedAssets={clientAsset ? [clientAsset] : []}
              select={selectedAsset => {
                if (clientAsset && selectedAsset.id === clientAsset.id) {
                  dispatch(clientSlice.actions.deselectAsset());
                  setDraftAsset(null);
                } else {
                  dispatch(clientSlice.actions.selectAsset(selectedAsset));
                  setDraftAsset(selectedAsset);
                }
              }}
              checkboxSelection
              onClickProductName={(id: string) => {
                dispatch(fetchFinancialProductDetailAsync(id));
              }}
              detailVisible={detailVisible}
              soldVisible={soldVisible}
              order={order}
              orderBy={orderBy}
              buildSorter={buildSorter}
              assets={filterdAssets}
            />
          </>
        )}
      </Box>
      <FinancialProductDetailDialog
        open={financialProductStatus !== 'idle'}
        product={financialProduct}
        isLoading={financialProductStatus === 'pending'}
        error={financialProductError}
        close={() => dispatch(financialProductSlice.actions.deselectProduct())}
      />
      <AssetAdditionDialog
        open={openAddProduct}
        closeDialog={toggleOpenAddProduct}
        showSuccessAddSnackbar={showAddSuccessSnackbar}
        showSuccessEditSnackbar={showEditSuccessSnackbar}
        showErrorSnackbar={showAssetErrorSnackbar}
        draftAsset={draftAsset}
        unselectProduct={() => setDraftAsset(null)}
        createAsset={({ clientId, data }) =>
          dispatch(createAssetAsync({ client_id: clientId, data })).unwrap()
        }
        putAsset={({ clientId, id, data }) =>
          dispatch(
            replaceAssetAsync({ client_id: clientId, id, data })
          ).unwrap()
        }
        client={client}
        financialProducts={financialProducts}
      />
      {draftAsset && (
        <DelProductDialog
          open={openDelProduct}
          close={toggleOpenDelProduct}
          asset={draftAsset}
          unselect={() => setDraftAsset(null)}
          deleteAsset={({ clientId, id }) =>
            dispatch(removeAssetAsync({ client_id: clientId, id })).unwrap()
          }
          clientId={client.id}
        />
      )}
    </>
  );
};
