import { Alert, Button, Grid } from '@mui/material';
import { NetWorthInputField } from './NetWorthInputField';
import { FinancialGoalInputField } from './FinancialGoalInputField';
import { MentorCommentInputField } from './MentorCommentInputField';
import { NextActionsInputField } from './NextActionsInputField';
import { ResultInputField } from './ResultInputField';
import { ReviewInputField } from './ReviewInputField';
import { TODOInputField } from './TODOInputField';
import RichTextEditor from './RichTextEditor/RichTextEditor';
import { MtgMemoHeader } from './MtgMemoHeader';
import {
  MentoringSheetFileWithTransferData,
  NewAPIMentoringSheet,
  WithId,
  WithTimestamp,
} from 'types';
import { ReferenceFilesUpload } from './ReferenceFilesUpload';
import { ReferenceFilesUploadHeader } from './ReferenceFilesUploadHeader';

type MentoringSheetFormProps = {
  draftSheet: WithId<NewAPIMentoringSheet>;
  setDraftSheet: React.Dispatch<
    React.SetStateAction<WithId<NewAPIMentoringSheet> | null>
  >;
  save: () => void;
  disableMentorComment?: boolean;
  mentoringSheetFiles: WithTimestamp<
    WithId<MentoringSheetFileWithTransferData>
  >[];
  getMentoringSheetDownloadLink: (file_id: string) => Promise<string>;
  uploadMentoringSheetFile: (file: File) => Promise<void>;
  removeMentoringSheetFile: (file_id: string) => Promise<void>;
  status: 'idle' | 'pending' | 'succeeded' | 'rejected';
  error: string | null;
};

export const MentoringSheetForm = ({
  draftSheet,
  setDraftSheet,
  save,
  disableMentorComment = false,
  mentoringSheetFiles,
  getMentoringSheetDownloadLink,
  uploadMentoringSheetFile,
  removeMentoringSheetFile,
  status,
  error,
}: MentoringSheetFormProps) => {
  return (
    <Grid item container spacing={2} sx={{ px: 2, py: 5 }}>
      <Grid item md={4}>
        <NetWorthInputField
          assetGoal={draftSheet.netWorthGoal}
          onChange={n =>
            setDraftSheet(prev =>
              prev
                ? {
                    ...prev,
                    netWorthGoal: n,
                  }
                : null
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FinancialGoalInputField
          goal={draftSheet.financialGoal}
          onChange={e =>
            setDraftSheet(prev =>
              prev
                ? {
                    ...prev,
                    financialGoal: e.target.value,
                  }
                : null
            )
          }
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TODOInputField
          todo={draftSheet.todo}
          onChange={e =>
            setDraftSheet(prev =>
              prev
                ? {
                    ...prev,
                    todo: e.target.value,
                  }
                : null
            )
          }
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ResultInputField
          result={draftSheet.result}
          onChange={e =>
            setDraftSheet(prev =>
              prev
                ? {
                    ...prev,
                    result: e.target.value,
                  }
                : null
            )
          }
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ReviewInputField
          review={draftSheet.review}
          onChange={e =>
            setDraftSheet(prev =>
              prev
                ? {
                    ...prev,
                    review: e.target.value,
                  }
                : null
            )
          }
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <NextActionsInputField
          nextActions={draftSheet.nextActions}
          onChange={e =>
            setDraftSheet(prev =>
              prev
                ? {
                    ...prev,
                    nextActions: e.target.value,
                  }
                : null
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <MentorCommentInputField
          mentorComment={draftSheet.mentorComment}
          disabled={disableMentorComment}
          onChange={e =>
            setDraftSheet(prev =>
              prev
                ? {
                    ...prev,
                    mentorComment: e.target.value,
                  }
                : null
            )
          }
        />
      </Grid>
      {/* <Grid item xs={12}>
      <MtgMemoInputField
        mtgMemo={sheet.mtgMemo}
        onChange={(e) => edit({ mtgMemog: e.target.value })}
      />
    </Grid> */}
      <Grid item xs={12}>
        <MtgMemoHeader />
        <RichTextEditor
          mtgMemoLexical={draftSheet.mtgMemoLexical}
          onChange={(editorStateJSON: string) => {
            setDraftSheet(prev =>
              prev
                ? {
                    ...prev,
                    mtgMemoLexical: editorStateJSON,
                  }
                : null
            );
          }}
          key={draftSheet.id}
        />
      </Grid>
      <Grid item xs={12}>
        <ReferenceFilesUploadHeader />
        <ReferenceFilesUpload
          sheetFiles={mentoringSheetFiles}
          getDownloadLink={getMentoringSheetDownloadLink}
          uploadFile={uploadMentoringSheetFile}
          removeFile={removeMentoringSheetFile}
        />
        {/* TODO */}
      </Grid>
      <Grid item>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={save}
          disabled={status === 'pending'}
          sx={{ mb: 2 }}
        >
          変更を保存する
        </Button>
        {status === 'rejected' && error && (
          <Alert severity="error">{error}</Alert>
        )}
        {status === 'succeeded' && (
          <Alert severity="success">変更を保存しました</Alert>
        )}
      </Grid>
    </Grid>
  );
};
