import { API_URL } from 'config';
import {
  ClientAssetHistoryAggregationByAssetType,
  ClientPayload,
  DraftClient,
  FinancialSummary,
  IAsset,
  IManualInput,
  NewApiClient,
  NewAPIMentoringSheetStorePayload,
  NewAPIMentoringSheetWithServerValues,
  PostClientMentoringSheetPayload,
} from 'types';

export const addClient = async ({
  token,
  payload,
}: {
  payload: ClientPayload;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      ...payload,
    }),
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<NewApiClient>;
};

export const getClients = async ({
  token,
  familyId,
}: {
  token: string;
  familyId?: string;
}) => {
  const response = await fetch(
    `${API_URL}/clients${familyId ? `?family_id=${familyId}` : ''}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<NewApiClient[]>;
};

export const getClientById = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<NewApiClient>;
};

export const putClientById = async ({
  id,
  data,
  token,
}: {
  id: string;
  data: DraftClient;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<NewApiClient>;
};

export const deleteClientById = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return;
};

export const getClientFinancialSummary = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${id}/assets/summary`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<FinancialSummary>;
};

export const getAssetsByClientId = async ({
  clientId,
  token,
}: {
  clientId: string;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${clientId}/assets`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<IAsset[]>;
};

export const getAssetsByFamilyId = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/families/${id}/assets`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<IAsset[]>;
};
export const getAssetById = async ({
  clientId,
  id,
  token,
}: {
  clientId: string;
  id: string;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${clientId}/assets/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<IAsset>;
};

export const postAsset = async ({
  clientId,
  data,
  token,
}: {
  clientId: string;
  data: IManualInput;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${clientId}/assets`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<IAsset>;
};

export const putAsset = async ({
  clientId,
  id,
  data,
  token,
}: {
  clientId: string;
  id: string;
  data: IManualInput;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${clientId}/assets/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<IAsset>;
};

export const deleteAsset = async ({
  client_id,
  id,
  token,
}: {
  client_id: string;
  id: string;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${client_id}/assets/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return;
};

export const getClientAssetHistoryAggregationByAssetType = async ({
  id,
  token,
  freq,
  start_at,
  end_at,
}: {
  id: string;
  token: string;
  freq: 'daily' | 'weekly' | 'monthly';
  start_at: string;
  end_at: string;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${id}/assets/history/aggregation-by-asset-type?start_at=${start_at}&end_at=${end_at}&freq=${freq}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<ClientAssetHistoryAggregationByAssetType[]>;
};

export const getClientMentoringSheets = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${id}/mentoring-sheets`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<NewAPIMentoringSheetWithServerValues[]>;
};

export const getClientMentoringSheet = async ({
  client_id,
  mentoring_sheet_id,
  token,
}: {
  client_id: string;
  mentoring_sheet_id: string;
  token: string;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<NewAPIMentoringSheetWithServerValues>;
};

export const postClientMentoringSheet = async ({
  client_id,
  data,
  token,
}: {
  client_id: string;
  token: string;
  data: PostClientMentoringSheetPayload;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<NewAPIMentoringSheetWithServerValues>;
};

export const putClientMentoringSheet = async ({
  client_id,
  id,
  data,
  token,
}: {
  client_id: string;
  id: string;
  token: string;
  data: NewAPIMentoringSheetStorePayload;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/${id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<NewAPIMentoringSheetWithServerValues>;
};

export const deleteClientMentoringSheet = async ({
  client_id,
  id,
  token,
}: {
  client_id: string;
  id: string;
  token: string;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/${id}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return;
};

export const getExcelBlobForClient = async ({
  client_id,
  token,
  order,
  orderBy,
}: {
  client_id: string;
  orderBy?: keyof IAsset;
  order?: 'asc' | 'desc';
  token: string;
}) => {
  const params = new URLSearchParams();
  if (orderBy && order) {
    params.append('orderBy', orderBy as string);
    params.append('order', order);
  }
  params.append('format', 'xlsx');
  const response = await fetch(
    `${API_URL}/clients/${client_id}/assets/download${
      params.size > 0 ? '?' + params.toString() : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.blob();
};
