import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { PasswordTextField } from 'parts/PasswordTextField';
import { useState } from 'react';
import { ClientPayload, WithServerManagedValuesFamily } from 'types';

interface AddClientDialogProps {
  open: boolean;
  onClose: () => void;
  familyOptions: WithServerManagedValuesFamily[];
  maxPortpholiohIdNumber: number;
  createClient: (payload: ClientPayload) => void;
  status: 'succeeded' | 'idle' | 'pending' | 'rejected';
  error: string | null;
}

export const AddClientDialog = ({
  open,
  onClose,
  familyOptions,
  maxPortpholiohIdNumber,
  createClient,
  status,
  error,
}: AddClientDialogProps) => {
  const initialFormState = {
    name: 'P' + (maxPortpholiohIdNumber + 1).toString().padStart(4, '0'),
    email: '',
    password: '',
    familyId: undefined,
    status: 'リード',
    clientBillingId: '',
  };

  const [formState, setFormState] = useState<{
    name: string;
    email: string;
    password: string;
    familyId?: string;
    status: string;
    clientBillingId: string;
  }>(initialFormState);

  const [showCreateFamilyMenu, setShowCreateFamilyMenu] =
    useState<boolean>(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          if (
            formState.name.length === 0 ||
            formState.email.length === 0 ||
            formState.password.length < 6
          )
            return;
          createClient(formState);
        },
      }}
    >
      <DialogTitle sx={{ mx: 4, mt: 2 }}>顧客新規登録</DialogTitle>
      <DialogContent sx={{ mx: 4 }}>
        <TextField
          required
          type="text"
          label="P王番号"
          value={formState.name}
          autoFocus
          autoComplete="off"
          sx={{ my: 2 }}
          variant="standard"
          onChange={event => {
            setFormState(prev => ({ ...prev, name: event.target.value }));
          }}
          fullWidth
        />
        <div>
          <TextField
            required
            type="email"
            label="メールアドレス"
            value={formState.email}
            autoComplete="off"
            sx={{ my: 2 }}
            variant="standard"
            onChange={event => {
              setFormState(prev => ({ ...prev, email: event.target.value }));
            }}
            fullWidth
          />
        </div>
        <div>
          <PasswordTextField
            label="パスワード（6文字以上）"
            value={formState.password!}
            id="new-client-password-input"
            onChange={event => {
              setFormState(prev => ({
                ...prev,
                password: event.target.value,
              }));
            }}
            inputProps={{ minLength: 6 }}
            sx={{ my: 2 }}
            variant="standard"
            autoComplete="new-password"
            fullWidth
          />
        </div>
        <div>
          <FormControl variant="standard" sx={{ xmy: 2, width: '200px' }}>
            <InputLabel id="family-simple-select-label" shrink={true}>
              ファミリー
            </InputLabel>
            <Select
              labelId="family-simple-select-label"
              id="family-simple-select"
              value={formState.familyId ?? ''}
              onChange={event => {
                const value = event.target.value as string;
                setFormState(prev => ({
                  ...prev,
                  familyId: value.length === 0 ? undefined : value,
                }));
              }}
              sx={{ my: 2 }}
              variant="standard"
              displayEmpty
            >
              <MenuItem value="">
                <em>未選択</em>
              </MenuItem>
              {familyOptions.map(family => (
                <MenuItem key={family.id} value={family.id}>
                  {family.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl variant="standard" sx={{ my: 2, width: '200px' }}>
            <InputLabel id="client-status-select-label">ステータス</InputLabel>
            <Select
              required
              labelId="client-status-select-label"
              value={formState.status}
              label="ステータス"
              onChange={event => {
                setFormState(prev => ({
                  ...prev,
                  status: event.target.value as
                    | '顧客'
                    | 'リード'
                    | 'デモ'
                    | '解約済み',
                }));
              }}
            >
              {['顧客', 'リード', 'デモ', '解約済み'].map(clientStatus => (
                <MenuItem key={clientStatus} value={clientStatus}>
                  {clientStatus}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <TextField
            type="text"
            label="請求先番号"
            value={formState.clientBillingId}
            autoComplete="off"
            sx={{ my: 2 }}
            variant="standard"
            onChange={event => {
              setFormState(prev => ({
                ...prev,
                clientBillingId: event.target.value,
              }));
            }}
            fullWidth
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start', mx: 6, mb: 4 }}>
        <Button variant="outlined" onClick={onClose}>
          キャンセル
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={
            formState.name.length === 0 ||
            formState.email.length === 0 ||
            formState.password.length < 6 ||
            status === 'pending'
          }
        >
          {status === 'pending' ? '登録中...' : '新規登録'}
        </Button>
      </DialogActions>
      {status === 'rejected' && (
        <div style={{ textAlign: 'right', margin: 12, color: 'red' }}>
          {error ?? '新規登録に失敗しました'}
        </div>
      )}
    </Dialog>
  );
};
