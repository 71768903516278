import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import {
  IsinAdditionPanel,
  AlternativeAdditionPanel,
  CacheAdditionPanel,
} from './AssetAdditionPanels';
import { useNumericalState, useSelectState, useTextState } from './customHooks';
import { serverTimestamp } from 'firebase/firestore';
import { IAsset, IManualInput } from 'types/asset';
import { NewApiClient } from 'types/client';
import { FinancialProductListITem } from 'types';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

interface IAssetAdditionDialog {
  open: boolean;
  closeDialog: () => void;
  showSuccessAddSnackbar: () => void;
  showSuccessEditSnackbar: () => void;
  showErrorSnackbar: () => void;
  draftAsset: IAsset | null;
  unselectProduct: () => void;
  createAsset: (payload: {
    clientId: string;
    data: IManualInput;
  }) => Promise<IAsset>;
  putAsset: (payload: {
    clientId: string;
    id: string;
    data: IManualInput;
  }) => Promise<IAsset>;
  client: NewApiClient | undefined;
  financialProducts: FinancialProductListITem[];
}

export const AssetAdditionDialog = ({
  open,
  closeDialog,
  showSuccessAddSnackbar,
  showSuccessEditSnackbar,
  showErrorSnackbar,
  draftAsset,
  unselectProduct,
  createAsset,
  putAsset,
  client,
  financialProducts,
}: IAssetAdditionDialog) => {
  const [tabIx, setTabIx] = useState<number>(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { clientId: _clientId } = useParams();
  const clientId = _clientId!;
  const [disabledTab_p, setDisabledTab_p] = useState<boolean>(false);
  const [disabledTab_a, setDisabledTab_a] = useState<boolean>(false);
  const [disabledTab_c, setDisabledTab_c] = useState<boolean>(false);

  // tabIx = 0 '債券 &middot; 株式 &middot; ファンド'
  // const [draftSecurity, setDraftSecurity] = useState<object>({})

  const [selectedFinancialProduct, setSelectedFinancialProduct] =
    useState<FinancialProductListITem | null>(
      financialProducts.find(product => product.id === draftAsset?.bbgDocId) ??
        null
    );
  const [isProductSelectedError, setIsProductSelectedError] = useState(false);

  const [
    brokerageAccount_p,
    setBrokerageAccount_p,
    {
      props: brokerageAccountProps_p,
      showError: showbrokerageAccountError_p,
      reset: resetBrokerageAccount_p,
    },
  ] = useSelectState('');
  const [
    accountType_p,
    setAccountType_p,
    { props: accountTypeProps_p, reset: resetAccountType_p },
  ] = useSelectState('指定なし');
  const [
    amount_p,
    setAmount_p,
    {
      props: amountProps_p,
      showError: showAmountError_p,
      reset: resetAmount_p,
    },
  ] = useNumericalState(null);
  const [
    purchasePrice_p,
    setPurchasePrice_p,
    {
      props: purchasePriceProps_p,
      showError: showPurchasePriceError_p,
      reset: resetPurchasePrice_p,
    },
  ] = useNumericalState(null);
  const [
    paymentLocal_p,
    setPaymentLocal_p,
    {
      props: paymentLocalProps_p,
      showError: showPaymentLocalError_p,
      reset: resetPaymentLocal_p,
    },
  ] = useNumericalState(null);
  const [
    paymentDomestic_p,
    setPaymentDomestic_p,
    {
      props: paymentDomesticProps_p,
      showError: showPaymentDomesticError_p,
      reset: resetPaymentDomestic_p,
    },
  ] = useNumericalState(null);
  const [
    distributionLocal_p,
    setDistributionLocal_p,
    {
      props: distributionLocalProps_p,
      showError: showDistributionLocalError_p,
      reset: resetDistributionLocal_p,
    },
  ] = useNumericalState(0);
  const [
    salesProceedsLocal_p,
    setSalesProceedsLocal_p,
    {
      props: salesProceedsLocalProps_p,
      showError: showSalesProceedsLocalError_p,
      reset: resetSalesProceedsLocal_p,
    },
  ] = useNumericalState(0);
  const [
    salesProceedsDomestic_p,
    setSalesProceedsDomestic_p,
    {
      props: salesProceedsDomesticProps_p,
      showError: showSalesProceedsDomesticError_p,
      reset: resetSalesProceedsDomestic_p,
    },
  ] = useNumericalState(0);

  // tabIx = 1 オルタナティブ
  // const [draftAlt, setDraftAlt] = useState<object>({})
  const [
    productName_a,
    setProductName_a,
    {
      props: productNameProps_a,
      showError: showProductNameError_a,
      reset: resetProductName_a,
    },
  ] = useTextState('');
  const [
    currency_a,
    setCurrency_a,
    {
      props: currencyProps_a,
      showError: showCurrencyError_a,
      reset: resetCurrency_a,
    },
  ] = useSelectState('');
  const [
    unit_a,
    setUnit_a,
    { props: unitProps_a, showError: showUnitError_a, reset: resetUnit_a },
  ] = useSelectState('');
  const [
    productType_a,
    setProductType_a,
    {
      props: productTypeProps_a,
      showError: showProductTypeError_a,
      reset: resetProductType_a,
    },
  ] = useSelectState('');
  const [
    marketPrice_a,
    setMarketPrice_a,
    {
      props: marketPriceProps_a,
      showError: showMarketPriceError_a,
      reset: resetMarketPrice_a,
    },
  ] = useNumericalState(null);
  const [
    calcFlag_a,
    setCalcFlag_a,
    {
      props: calcFlagProps_a,
      showError: showCalcFlagError_a,
      reset: resetCalcFlag_a,
    },
  ] = useNumericalState(1);
  const [
    brokerageAccount_a,
    setBrokerageAccount_a,
    {
      props: brokerageAccountProps_a,
      showError: showbrokerageAccountError_a,
      reset: resetBrokerageAccount_a,
    },
  ] = useSelectState('');
  const [
    amount_a,
    setAmount_a,
    {
      props: amountProps_a,
      showError: showAmountError_a,
      reset: resetAmount_a,
    },
  ] = useNumericalState(null);
  const [
    purchasePrice_a,
    setPurchasePrice_a,
    {
      props: purchasePriceProps_a,
      showError: showPurchasePriceError_a,
      reset: resetPurchasePrice_a,
    },
  ] = useNumericalState(null);
  const [
    paymentLocal_a,
    setPaymentLocal_a,
    {
      props: paymentLocalProps_a,
      showError: showPaymentLocalError_a,
      reset: resetPaymentLocal_a,
    },
  ] = useNumericalState(null);
  const [
    paymentDomestic_a,
    setPaymentDomestic_a,
    {
      props: paymentDomesticProps_a,
      showError: showPaymentDomesticError_a,
      reset: resetPaymentDomestic_a,
    },
  ] = useNumericalState(null);
  const [
    distributionLocal_a,
    setDistributionLocal_a,
    {
      props: distributionLocalProps_a,
      showError: showDistributionLocalError_a,
      reset: resetDistributionLocal_a,
    },
  ] = useNumericalState(0);
  const [
    salesProceedsLocal_a,
    setSalesProceedsLocal_a,
    {
      props: salesProceedsLocalProps_a,
      showError: showSalesProceedsLocalError_a,
      reset: resetSalesProceedsLocal_a,
    },
  ] = useNumericalState(0);
  const [
    salesProceedsDomestic_a,
    setSalesProceedsDomestic_a,
    {
      props: salesProceedsDomesticProps_a,
      showError: showSalesProceedsDomesticError_a,
      reset: resetSalesProceedsDomestic_a,
    },
  ] = useNumericalState(0);

  // tabIx = 2
  // const [draftCache, settDraftCache] = useState<object>({})
  const [
    productName_c,
    setProductName_c,
    {
      props: productNameProps_c,
      showError: showProductNameError_c,
      reset: resetProductName_c,
    },
  ] = useTextState('');
  const [
    currency_c,
    setCurrency_c,
    {
      props: currencyProps_c,
      showError: showCurrencyError_c,
      reset: resetCurrency_c,
    },
  ] = useSelectState('');
  const [
    unit_c,
    setUnit_c,
    { props: unitProps_c, showError: showUnitError_c, reset: resetUnit_c },
  ] = useSelectState('');
  const [
    brokerageAccount_c,
    setBrokerageAccount_c,
    {
      props: brokerageAccountProps_c,
      showError: showbrokerageAccountError_c,
      reset: resetBrokerageAccount_c,
    },
  ] = useSelectState('');
  const [
    amount_c,
    setAmount_c,
    {
      props: amountProps_c,
      showError: showAmountError_c,
      reset: resetAmount_c,
    },
  ] = useNumericalState(null);

  useEffect(() => {
    resetForm();
    if (draftAsset === null) {
      return;
    }
    switch (draftAsset.productType) {
      case 'キャッシュ':
        setTabIx(2);
        setDisabledTab_p(true);
        setDisabledTab_a(true);
        setDisabledTab_c(false);
        setProductName_c(draftAsset.productName);
        setCurrency_c(draftAsset.currency);
        setUnit_c(draftAsset.unit);
        setBrokerageAccount_c(draftAsset.brokerageAccount);
        setAmount_c(draftAsset.amount);
        break;
      case 'オルタナティブ':
      case '非上場株式':
      case '不動産':
      case 'その他':
        setTabIx(1);
        setDisabledTab_p(true);
        setDisabledTab_a(false);
        setDisabledTab_c(true);
        setProductName_a(draftAsset.productName);
        setCurrency_a(draftAsset.currency);
        setUnit_a(draftAsset.unit);
        setProductType_a(draftAsset.productType);
        setMarketPrice_a(draftAsset.marketPrice!); //TODO null check
        setCalcFlag_a(draftAsset.calcFlag || 1);
        setBrokerageAccount_a(draftAsset.brokerageAccount);
        setAmount_a(draftAsset.amount);
        setPurchasePrice_a(draftAsset.purchasePrice);
        setPaymentLocal_a(draftAsset.paymentLocal);
        setPaymentDomestic_a(draftAsset.paymentDomestic);
        setDistributionLocal_a(draftAsset.distributionLocal);
        setSalesProceedsLocal_a(draftAsset.salesProceedsLocal);
        setSalesProceedsDomestic_a(draftAsset.salesProceedsDomestic);
        break;
      default:
        setTabIx(0);
        setDisabledTab_p(false);
        setDisabledTab_a(true);
        setDisabledTab_c(true);
        setSelectedFinancialProduct(
          financialProducts.find(p => p.id === draftAsset.bbgDocId) ?? null
        );
        setBrokerageAccount_p(draftAsset.brokerageAccount);
        setAccountType_p(draftAsset.accountType ?? '');
        setAmount_p(draftAsset.amount);
        setPurchasePrice_p(draftAsset.purchasePrice);
        setPaymentLocal_p(draftAsset.paymentLocal);
        setPaymentDomestic_p(draftAsset.paymentDomestic);
        setDistributionLocal_p(draftAsset.distributionLocal);
        setSalesProceedsLocal_p(draftAsset.salesProceedsLocal);
        setSalesProceedsDomestic_p(draftAsset.salesProceedsDomestic);
        break;
    }
    return resetForm;
  }, [draftAsset]);

  const resetForm = () => {
    setTabIx(0);
    setDisabledTab_p(false);
    setDisabledTab_a(false);
    setDisabledTab_c(false);
    // p
    setSelectedFinancialProduct(null);
    resetBrokerageAccount_p();
    resetAccountType_p();
    resetAmount_p();
    resetPurchasePrice_p();
    resetPaymentLocal_p();
    resetPaymentDomestic_p();
    resetDistributionLocal_p();
    resetSalesProceedsLocal_p();
    resetSalesProceedsDomestic_p();
    // a
    resetProductName_a();
    resetCurrency_a();
    resetUnit_a();
    resetProductType_a();
    resetMarketPrice_a();
    resetBrokerageAccount_a();
    resetAmount_a();
    resetPurchasePrice_a();
    resetPaymentLocal_a();
    resetPaymentDomestic_a();
    resetDistributionLocal_a();
    resetSalesProceedsLocal_a();
    resetSalesProceedsDomestic_a();
    // c
    resetProductName_c();
    resetCurrency_c();
    resetUnit_c();
    resetBrokerageAccount_c();
    resetAmount_c();
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIx(newValue);
  };

  const onSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    switch (tabIx) {
      case 0:
        onSubmit_p(event);
        break;
      case 1:
        onSubmit_a(event);
        break;
      case 2:
        onSubmit_c(event);
    }
  };

  const onSubmit_p = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let hasError = false;
    console.log(selectedFinancialProduct);
    console.log(!selectedFinancialProduct);
    if (!selectedFinancialProduct) {
      setIsProductSelectedError(true);
      hasError = true;
    }
    if (brokerageAccount_p === '') {
      showbrokerageAccountError_p();
      hasError = true;
    }
    if (amount_p === null) {
      showAmountError_p();
      hasError = true;
    }
    if (purchasePrice_p === null) {
      showPurchasePriceError_p();
      hasError = true;
    }
    if (paymentLocal_p === null) {
      showPaymentLocalError_p();
      hasError = true;
    }
    if (paymentDomestic_p === null) {
      showPaymentDomesticError_p();
      hasError = true;
    }
    if (distributionLocal_p === null) {
      showDistributionLocalError_p();
      hasError = true;
    }
    if (salesProceedsLocal_p === null) {
      showSalesProceedsLocalError_p();
      hasError = true;
    }
    if (salesProceedsDomestic_p === null) {
      showSalesProceedsDomesticError_p();
      hasError = true;
    }
    if (hasError) {
      return;
    }
    const inputFields = {
      clientId: clientId,
      isin: selectedFinancialProduct?.isin.split('_')[0],
      bbgDocId: selectedFinancialProduct?.id,
      brokerageAccount: brokerageAccount_p,
      accountType: accountType_p,
      productType: selectedFinancialProduct?.manual_class,
      currency: selectedFinancialProduct?.crncy,
      productName: selectedFinancialProduct?.manual_name,
      amount: amount_p,
      unit: selectedFinancialProduct?.manual_unit,
      purchasePrice: purchasePrice_p,
      paymentLocal: paymentLocal_p,
      paymentDomestic: paymentDomestic_p,
      distributionLocal: distributionLocal_p,
      salesProceedsLocal: salesProceedsLocal_p,
      salesProceedsDomestic: salesProceedsDomestic_p,
      manuallyUpdatedAt: serverTimestamp(),
    };
    try {
      if (!draftAsset) {
        await createAsset({ clientId, data: inputFields as IManualInput });
        showSuccessAddSnackbar();
        resetForm();
      } else {
        await putAsset({
          clientId,
          id: draftAsset.id,
          data: inputFields,
        } as any);
        showSuccessEditSnackbar();
        unselectProduct();
      }
      closeDialog();
    } catch (e) {
      showErrorSnackbar();
    }
  };

  const onSubmit_a = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let hasError = false;
    if (productName_a === '') {
      showProductNameError_a();
      hasError = true;
    }
    if (currency_a === '') {
      showCurrencyError_a();
      hasError = true;
    }
    if (unit_a === '') {
      showUnitError_a();
      hasError = true;
    }
    if (productType_a === '') {
      showProductTypeError_a();
      hasError = true;
    }
    if (marketPrice_a === null) {
      showMarketPriceError_a();
      hasError = true;
    }
    if (calcFlag_a === null) {
      showCalcFlagError_a();
      hasError = true;
    }
    if (brokerageAccount_a === '') {
      showbrokerageAccountError_a();
      hasError = true;
    }
    if (amount_a === null) {
      showAmountError_a();
      hasError = true;
    }
    if (purchasePrice_a === null) {
      showPurchasePriceError_a();
      hasError = true;
    }
    if (paymentLocal_a === null) {
      showPaymentLocalError_a();
      hasError = true;
    }
    if (paymentDomestic_a === null) {
      showPaymentDomesticError_a();
      hasError = true;
    }
    if (distributionLocal_a === null) {
      showDistributionLocalError_a();
      hasError = true;
    }
    if (salesProceedsLocal_a === null) {
      showSalesProceedsLocalError_a();
      hasError = true;
    }
    if (salesProceedsDomestic_a === null) {
      showSalesProceedsDomesticError_a();
      hasError = true;
    }
    if (selectedFinancialProduct === null) {
    }
    if (hasError) {
      return;
    }

    const inputFields = {
      clientId: clientId,
      brokerageAccount: brokerageAccount_a,
      marketPrice: marketPrice_a,
      calcFlag: calcFlag_a,
      productType: productType_a,
      currency: currency_a,
      productName: productName_a,
      amount: amount_a,
      unit: unit_a,
      purchasePrice: purchasePrice_a,
      paymentLocal: paymentLocal_a,
      paymentDomestic: paymentDomestic_a,
      distributionLocal: distributionLocal_a,
      salesProceedsLocal: salesProceedsLocal_a,
      salesProceedsDomestic: salesProceedsDomestic_a,
      manuallyUpdatedAt: serverTimestamp(),
    };
    try {
      if (!draftAsset) {
        await createAsset({ clientId: clientId, data: inputFields as any });
        showSuccessAddSnackbar();
        resetForm();
      } else {
        await putAsset({
          clientId,
          id: draftAsset.id,
          data: inputFields,
        } as any);
        showSuccessEditSnackbar();
        unselectProduct();
      }
      closeDialog();
    } catch (e) {
      console.log(e);
      showErrorSnackbar();
    }
  };

  const onSubmit_c = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let hasError = false;
    if (productName_c === '') {
      showProductNameError_c();
      hasError = true;
    }
    if (currency_c === '') {
      showCurrencyError_c();
      hasError = true;
    }
    if (unit_c === '') {
      showUnitError_c();
      hasError = true;
    }
    if (brokerageAccount_c === '') {
      showbrokerageAccountError_c();
      hasError = true;
    }
    if (amount_c === null) {
      showAmountError_c();
      hasError = true;
    }
    if (hasError) {
      return;
    }
    const inputFields = {
      clientId: clientId,
      brokerageAccount: brokerageAccount_c,
      productType: 'キャッシュ',
      currency: currency_c,
      productName: productName_c,
      amount: amount_c,
      unit: unit_c,
      manuallyUpdatedAt: serverTimestamp(),
    };
    try {
      if (!draftAsset) {
        await createAsset({ clientId: clientId, data: inputFields as any });
        showSuccessAddSnackbar();
        resetForm();
      } else {
        await putAsset({
          clientId,
          id: draftAsset.id,
          data: inputFields,
        } as any);
        showSuccessEditSnackbar();
        unselectProduct();
      }
      closeDialog();
    } catch (e) {
      console.log(e);
      showErrorSnackbar();
    }
  };

  const onCancel = () => {
    closeDialog();
    // resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullScreen={fullScreen}
      scroll="paper"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ ml: 4, mt: 2, p: 1, fontWeight: 'bold' }}>
        {draftAsset === null ? '銘柄新規追加' : '銘柄編集'} {client?.name}様
      </DialogTitle>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabIx}
          onChange={handleChange}
          aria-label="asset addition tab"
          variant="fullWidth"
        >
          <Tab
            label="債券 &middot; 株式 &middot; ファンド"
            disabled={disabledTab_p}
          />
          <Tab
            label="オルタナティブ &middot; その他"
            disabled={disabledTab_a}
          />
          <Tab label="キャッシュ" disabled={disabledTab_c} />
        </Tabs>
      </Box>
      <DialogContent>
        <TabPanel value={tabIx} index={0}>
          <IsinAdditionPanel
            brokerageAccountProps={brokerageAccountProps_p}
            accountTypeProps={accountTypeProps_p}
            amountProps={amountProps_p}
            purchasePriceProps={purchasePriceProps_p}
            paymentLocalProps={paymentLocalProps_p}
            paymentDomesticProps={paymentDomesticProps_p}
            distributionLocalProps={distributionLocalProps_p}
            salesProceedsLocalProps={salesProceedsLocalProps_p}
            salesProceedsDomesticProps={salesProceedsDomesticProps_p}
            financialProducts={financialProducts}
            selectedProduct={selectedFinancialProduct ?? undefined}
            setSelectedProduct={p => setSelectedFinancialProduct(p ?? null)}
            isProductSelectedError={isProductSelectedError}
            setIsProductSelectedError={setIsProductSelectedError}
          />
        </TabPanel>
        <TabPanel value={tabIx} index={1}>
          <AlternativeAdditionPanel
            productNameProps={productNameProps_a}
            currencyProps={currencyProps_a}
            unitProps={unitProps_a}
            productTypeProps={productTypeProps_a}
            marketPriceProps={marketPriceProps_a}
            calcFlagProps={calcFlagProps_a}
            brokerageAccountProps={brokerageAccountProps_a}
            amountProps={amountProps_a}
            purchasePriceProps={purchasePriceProps_a}
            paymentLocalProps={paymentLocalProps_a}
            paymentDomesticProps={paymentDomesticProps_a}
            distributionLocalProps={distributionLocalProps_a}
            salesProceedsLocalProps={salesProceedsLocalProps_a}
            salesProceedsDomesticProps={salesProceedsDomesticProps_a}
          />
        </TabPanel>
        <TabPanel value={tabIx} index={2}>
          <CacheAdditionPanel
            productNameProps={productNameProps_c}
            currencyProps={currencyProps_c}
            unitProps={unitProps_c}
            brokerageAccountProps={brokerageAccountProps_c}
            amountProps={amountProps_c}
          />
        </TabPanel>
      </DialogContent>
      <DialogActions sx={{ mt: 4, mr: 4, mb: 4 }}>
        <Button color="primary" onClick={onCancel} variant="outlined">
          キャンセル
        </Button>
        <Button color="primary" onClick={onSubmit} variant="contained">
          {draftAsset === null ? '新規追加' : '編集完了'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
