import { Table, TableContainer } from '@mui/material';
import { IAsset } from 'types/asset';
import { SortableTableHead } from './SortableTableHead';
import { AssetTableBody } from './AssetTableBody';
import { Order } from 'types';

interface IAssetTable {
  checkboxSelection?: boolean;
  selectedAssets?: IAsset[];
  select?: (asset: IAsset) => void;
  onClickProductName?: (bbgDocId: string) => void;
  detailVisible: boolean;
  soldVisible: boolean;
  order: Order;
  orderBy: keyof IAsset;
  buildSorter: (property: keyof IAsset) => void;
  assets: IAsset[];
}

export const AssetTable = ({
  checkboxSelection = false,
  selectedAssets = [],
  select,
  onClickProductName,
  detailVisible,
  soldVisible,
  order,
  orderBy,
  buildSorter,
  assets,
}: IAssetTable) => {
  return (
    <div>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 180px)' }}>
        <Table size="small" stickyHeader>
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            buildSorter={buildSorter}
            detailVisible={detailVisible}
          />
          <AssetTableBody
            assets={assets}
            order={order}
            orderBy={orderBy}
            checkboxSelection={checkboxSelection}
            selectedAssets={selectedAssets}
            onSelectAsset={select}
            onClickProductName={onClickProductName}
            detailVisible={detailVisible}
            soldVisible={soldVisible}
          />
        </Table>
      </TableContainer>
    </div>
  );
};
