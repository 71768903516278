import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import {
  deleteFamilyById,
  getAssetsByFamilyId,
  getFamilies,
  getFamilyAssetHistoryAggregationByAssetType,
  getFamilyById,
  getFamilyFinancialSummary,
  getToken,
  postFamily,
  putFamilyById,
} from 'api';
import { RootState } from 'app/store';
import {
  WithServerManagedValuesFamily,
  FamilyAssetHistoryAggregationByAssetType,
  FinancialSummary,
  IAsset,
  Family,
} from 'types';

type FamilyState = {
  families: WithServerManagedValuesFamily[];
  familiesStatus: 'idle' | 'pending' | 'succeeded' | 'rejected';
  status: 'idle' | 'pending' | 'succeeded' | 'rejected';
  error: string | null;
  family: WithServerManagedValuesFamily | null;
  assets: IAsset[];
  summary: FinancialSummary | null;
  historicalAggregationByAssetTypeMonthly: FamilyAssetHistoryAggregationByAssetType[];
};

const initialState: FamilyState = {
  familiesStatus: 'idle',
  families: [],
  family: null,
  status: 'idle',
  error: null,
  assets: [],
  summary: null,
  historicalAggregationByAssetTypeMonthly: [],
};

export const fetchFamiliesAsync = createAsyncThunk(
  'family/fetchFamilies',
  async () => {
    const token = await getToken();
    const response = await getFamilies(token);
    return response;
  }
);

export const fetchFamilyByIdAsync = createAsyncThunk(
  'family/fetchFamilyById',
  async ({ id }: { id: string }) => {
    const token = await getToken();
    const twoYearsAgo = new Date(
      new Date().setFullYear(new Date().getFullYear() - 2)
    ).toISOString();
    const today = new Date().toISOString();
    const [family, assets, summary, historicalAggregationByAssetType] =
      await Promise.all([
        getFamilyById({ id, token }),
        getAssetsByFamilyId({ id, token }),
        getFamilyFinancialSummary({ id, token }),
        getFamilyAssetHistoryAggregationByAssetType({
          id: id,
          token: token,
          freq: 'monthly',
          start_at: twoYearsAgo,
          end_at: today,
        }),
      ]);
    return { family, assets, summary, historicalAggregationByAssetType };
  }
);

export const createFamilyAsync = createAsyncThunk(
  'family/createFamily',
  async (data: Family) => {
    const token = await getToken();
    const response = await postFamily({ data, token });
    return response;
  }
);

export const replaceFamilyAsync = createAsyncThunk(
  'family/replaceFamily',
  async ({ id, data }: { id: string; data: WithServerManagedValuesFamily }) => {
    const token = await getToken();
    const response = await putFamilyById({ id, data, token });
    return response;
  }
);

export const removeFamilyByIdAsync = createAsyncThunk(
  'family/deleteFamilyById',
  async ({ id }: { id: string }) => {
    const token = await getToken();
    const response = await deleteFamilyById({ id, token });
    return response;
  }
);

export const familySlice = createSlice({
  name: 'family',
  initialState,
  reducers: {
    reset(state) {
      return {
        ...initialState,
        families: state.families,
      };
    },
    deselectFamily(state) {
      state.family = null;
      state.assets = [];
      state.summary = null;
      state.historicalAggregationByAssetTypeMonthly = [];
    },
    dismissError(state) {
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchFamiliesAsync.pending, state => {
        state.familiesStatus = 'pending';
      })
      .addCase(fetchFamiliesAsync.fulfilled, (state, action) => {
        state.familiesStatus = 'succeeded'; // fetch families only once.
        state.families = action.payload;
      })
      .addCase(fetchFamiliesAsync.rejected, state => {
        state.familiesStatus = 'rejected';
      })
      .addCase(fetchFamilyByIdAsync.pending, state => {
        state.status = 'pending';
      })
      .addCase(fetchFamilyByIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.family = action.payload.family;
        state.assets = action.payload.assets;
        state.summary = action.payload.summary;
        state.historicalAggregationByAssetTypeMonthly =
          action.payload.historicalAggregationByAssetType;
      })
      .addCase(fetchFamilyByIdAsync.rejected, state => {
        state.status = 'rejected';
      })
      .addCase(createFamilyAsync.pending, state => {
        state.status = 'pending';
      })
      .addCase(createFamilyAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.families = [action.payload, ...state.families];
      })
      .addCase(createFamilyAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(replaceFamilyAsync.pending, state => {
        state.status = 'pending';
      })
      .addCase(replaceFamilyAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const id = action.meta.arg.id;
        const family = action.payload;
        const idx = state.families.findIndex(f => f.id === id);
        state.families = [
          ...state.families.slice(0, idx),
          family,
          ...state.families.slice(idx + 1),
        ];
      })
      .addCase(replaceFamilyAsync.rejected, state => {
        state.status = 'rejected';
      })
      .addCase(removeFamilyByIdAsync.pending, state => {
        state.status = 'pending';
      })
      .addCase(removeFamilyByIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const id = action.meta.arg.id;
        state.families = state.families.filter(f => f.id !== id);
      })
      .addCase(removeFamilyByIdAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      });
  },
});

export const selectFamiliesStatus = (state: RootState) =>
  state.family.familiesStatus;
export const selectFamilies = (state: RootState) => state.family.families;
export const selectFamily = (state: RootState) => state.family.family;
export const selectFamilyStatus = (state: RootState) => state.family.status;
export const selectFamilyError = (state: RootState) => state.family.error;
export const selectFamilyAssets = (state: RootState) => state.family.assets;
export const selectFamilyFinancialSummary = (state: RootState) =>
  state.family.summary;
export const selectFamilyHistoricalAggregationByAssetTypeMonthly = (
  state: RootState
) => state.family.historicalAggregationByAssetTypeMonthly;
export const selectFamilyById = createSelector(
  [
    (state: RootState) => state.family.families,
    (state: RootState, id: string) => id,
  ],
  (families: WithServerManagedValuesFamily[], id: string) =>
    families.find(f => f.id === id) ?? null
);
