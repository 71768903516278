import { IAsset } from 'types';
import { API_URL } from 'config';

export const getExcelBlobForAllClients = async ({
  order,
  orderBy,
  token,
}: {
  orderBy?: keyof IAsset;
  order?: 'asc' | 'desc';
  token: string;
}) => {
  const params = new URLSearchParams();
  if (orderBy && order) {
    params.append('orderBy', orderBy as string); // TODO fix type casting
    params.append('order', order);
  }
  params.append('format', 'xlsx');
  const response = await fetch(
    `${API_URL}/assets/download${
      params.size > 0 ? '?' + params.toString() : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.blob();
};
