import { Box, Typography } from '@mui/material';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

export const ReferenceFilesUploadHeader = () => {
  return (
    <Box display="flex" alignItems="center" sx={{ mb: 0.2 }}>
      <SquareRoundedIcon style={{ color: 'hsl(340, 90%, 80%)' }} />
      <Typography
        sx={{ fontSize: '1rem', fontWeight: 'bold', color: '#0b4862', ml: 0.5 }}
      >
        参考資料
      </Typography>
    </Box>
  );
};
