import { useEffect } from 'react';
import { Outlet, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, IconButton, Typography } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { SelectClient, Loading, ClientMenuTabs } from 'components';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  clientSlice,
  fetchClientByIdAsync,
  fetchMentoringSheetFilesAsync,
  mentoringSheetFileSlice,
  selectClient,
  selectClientAsset,
  selectClientError,
  selectMentoringSheet,
  selectClientStatus,
  selectFamilyById,
  selectFamilyClients,
  fetchMentoringSheetsByClientIdAsync,
} from 'features';
import { useAppContext } from 'contexts';

export const AdminClientLayout = () => {
  const { clientId } = useParams() as { clientId: string };
  const dispatch = useAppDispatch();
  const client = useAppSelector(selectClient);
  const family = useAppSelector(state =>
    selectFamilyById(state, client?.familyId ?? '')
  );
  const familyClients = useAppSelector(state =>
    selectFamilyClients(state, client?.familyId ?? '')
  ); // TODO: handle null family properly
  const clientStatus = useAppSelector(selectClientStatus);
  const clientError = useAppSelector(selectClientError);
  const asset = useAppSelector(selectClientAsset);
  const mentoringSheet = useAppSelector(selectMentoringSheet);
  const { setDraftAsset, setDraftMentoringSheet } = useAppContext();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  // need fetch mentoring sheets. A dashboard page and a mentoring page need them.
  useEffect(() => {
    dispatch(fetchClientByIdAsync({ id: clientId }));
    dispatch(fetchMentoringSheetsByClientIdAsync({ id: clientId }));
  }, [dispatch, clientId]);
  useEffect(() => {
    if (mentoringSheet === null) {
      setDraftMentoringSheet(null);
      dispatch(mentoringSheetFileSlice.actions.reset());
    } else {
      const { createdAt, updatedAt, ...sheetWithId } = mentoringSheet;
      setDraftMentoringSheet(sheetWithId);
      dispatch(
        fetchMentoringSheetFilesAsync({
          client_id: clientId,
          mentoring_sheet_id: mentoringSheet.id,
        })
      );
    }
  }, [mentoringSheet, clientId, dispatch, setDraftMentoringSheet]);

  // need fetch asset to fetch current evaluation value of total assets.
  useEffect(() => {
    if (asset === null) {
      setDraftAsset(null);
    } else {
      const { createdAt, updatedAt, ...assetWithId } = asset;
      setDraftAsset(assetWithId);
    }
  }, [asset, setDraftAsset]);

  if (clientStatus === 'idle' || clientStatus === 'pending') {
    return <Loading />;
  }

  if (clientStatus === 'rejected' && clientError) {
    return (
      <Box sx={{ textAlign: 'center', my: 2 }}>
        <Typography variant="body1">{clientError}</Typography>
        <div>
          <Button
            sx={{ my: 1 }}
            onClick={() => {
              dispatch(clientSlice.actions.dismissError());
            }}
          >
            OK
          </Button>
        </div>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{ minWidth: 120, borderBottom: 'solid 1px #ddd' }}
        display="flex"
        alignItems="center"
      >
        <IconButton
          onClick={() => {
            navigate('/clients');
          }}
        >
          <ArrowBackRoundedIcon sx={{ verticalAlign: 'bottom', ml: '2px' }} />
        </IconButton>
        {
          family ? (
            <SelectClient
              clients={familyClients}
              family={family}
              currentId={client!.id} // TODO type safe
            />
          ) : (
            <Typography
              sx={{
                ml: 2,
                '&::after': {
                  content: '"様"',
                  marginLeft: '0.25em',
                },
              }}
              variant="subtitle1"
            >
              {client!.name}
            </Typography>
          ) // TODO type safe
        }
        <ClientMenuTabs
          sx={{ mx: 2 }}
          clientId={client?.id ?? ''}
          currentPath={pathname}
        />
      </Box>
      <Outlet />
    </>
  );
};
