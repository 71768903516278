import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  CreateFamilyDialog,
  DeleteFamilyDialog,
  EditFamilyDialog,
  FamiliesTable,
  Loading,
  MainMenuTabs,
} from 'components';
import {
  createFamilyAsync,
  familySlice,
  fetchFamiliesAsync,
  removeFamilyByIdAsync,
  replaceFamilyAsync,
  selectFamilies,
  selectFamiliesStatus,
  selectFamilyError,
  selectFamilyStatus,
} from 'features';
import { useEffect, useState } from 'react';
import { Family, WithServerManagedValuesFamily } from 'types';

export const FamiliesPage = () => {
  const dispatch = useAppDispatch();
  const families = useAppSelector(selectFamilies);
  const familiesStatus = useAppSelector(selectFamiliesStatus);
  const familyStatus = useAppSelector(selectFamilyStatus);
  const familyError = useAppSelector(selectFamilyError);
  const [selectedFamily, setSelectedFamily] =
    useState<WithServerManagedValuesFamily | null>(null);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState<boolean>(false);
  const [isOpenCreateDialog, setIsOpenCreateDialog] = useState<boolean>(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
  const [draftFamily, setDraftFamily] = useState<Family>({ name: '' });

  useEffect(() => {
    if (familiesStatus === 'idle') {
      dispatch(fetchFamiliesAsync());
    }
  });

  const handleSelectionChange = (family: WithServerManagedValuesFamily) => {
    if (selectedFamily && family.id === selectedFamily.id) {
      setSelectedFamily(null); // すでに選択されている家族を選択した場合、選択を解除する
    } else {
      setSelectedFamily(family); // 新しく選択された家族を設定する
    }
  };

  const handleEdit = async (formFamily: WithServerManagedValuesFamily) => {
    if (selectedFamily) {
      try {
        dispatch(
          replaceFamilyAsync({ id: selectedFamily.id, data: formFamily })
        );
      } catch (e) {
        console.log(e);
      }
    } else {
      console.error('No family selected.');
    }
  };

  return (
    <>
      <MainMenuTabs tabValue="/families" />
      <Box sx={{ px: 4, py: 1 }}>
        <div style={{ height: '20px' }}>
          <Tooltip title="ファミリーを追加">
            <IconButton
              aria-label="add-family"
              onClick={() => setIsOpenCreateDialog(true)}
            >
              <AddCircleIcon color="primary" />
            </IconButton>
          </Tooltip>
          {selectedFamily && (
            <>
              <Tooltip title="ファミリーを編集">
                <IconButton
                  aria-label="edit-family"
                  onClick={() => {
                    setSelectedFamily(selectedFamily);
                    setIsOpenEditDialog(true);
                  }}
                >
                  <EditIcon color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip title="ファミリーを削除">
                <IconButton
                  aria-label="delete-family"
                  onClick={() => setIsOpenDeleteDialog(true)}
                >
                  <DeleteIcon color="primary" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
        {familiesStatus === 'pending' ? (
          <Loading />
        ) : familiesStatus === 'rejected' ? (
          familyError
        ) : (
          <FamiliesTable
            families={families ?? []}
            selectedFamily={selectedFamily}
            onSelectionChangeRequest={handleSelectionChange}
          />
        )}
        <CreateFamilyDialog
          open={isOpenCreateDialog}
          onClose={() => setIsOpenCreateDialog(false)}
          family={draftFamily}
          setFamily={setDraftFamily}
          submit={family => {
            dispatch(createFamilyAsync(family))
              .unwrap()
              .then(() => {
                setIsOpenCreateDialog(false);
                setDraftFamily({ name: '' });
              })
              .catch(error => {
                console.error('Family creation failed:', error);
              });
          }}
          status={familyStatus}
          error={familyError}
          dismissError={() => dispatch(familySlice.actions.dismissError())}
        />
        {selectedFamily && (
          <>
            <EditFamilyDialog
              open={isOpenEditDialog}
              onClose={() => {
                setIsOpenEditDialog(false);
              }}
              family={selectedFamily}
              onSave={async (formFamily: WithServerManagedValuesFamily) => {
                dispatch(
                  replaceFamilyAsync({
                    id: selectedFamily.id,
                    data: formFamily,
                  })
                )
                  .unwrap()
                  .then(() => {
                    setIsOpenEditDialog(false);
                  })
                  .catch(error => {
                    console.error('Family update failed:', error);
                  });
              }}
              status={familyStatus}
              error={familyError}
            />
            <DeleteFamilyDialog
              open={isOpenDeleteDialog}
              onClose={() => {
                setIsOpenDeleteDialog(false);
                dispatch(familySlice.actions.dismissError());
              }}
              submit={() => {
                dispatch(removeFamilyByIdAsync({ id: selectedFamily.id }))
                  .unwrap()
                  .then(() => {
                    setSelectedFamily(null);
                    setIsOpenDeleteDialog(false);
                  })
                  .catch(error => {
                    console.error('Family deletion failed:', error);
                  });
              }}
              family={selectedFamily}
              status={familyStatus}
              error={familyError}
            />
          </>
        )}
      </Box>
    </>
  );
};
