import { useState } from 'react';
import { IAsset } from 'types/asset';
import { TableFilterOption } from '../components/Asset/AssetFilter';

interface IFilterKey {
  証券口座: string[];
  通貨: string[];
  資産クラス: string[];
}

export const useAssetFilter = () => {
  const [tableFilterOptions, setTableFilterOptions] = useState<
    TableFilterOption[]
  >([]);
  const setOptions = (values: TableFilterOption[]) => {
    setTableFilterOptions(values);
  };
  return [tableFilterOptions, setOptions] as const;
};

export const filterAssets = (
  allAssets: IAsset[],
  tableFilterOptions: TableFilterOption[]
) => {
  if (tableFilterOptions.length === 0) {
    return allAssets;
  }
  const filterKey = tableFilterOptions.reduce<IFilterKey>(
    (prev, cur) => {
      prev[cur.field].push(cur.name);
      return prev;
    },
    { 証券口座: [], 通貨: [], 資産クラス: [] }
  );
  const assets = allAssets.filter(a => {
    return (
      (filterKey['証券口座'].length === 0 ||
        filterKey['証券口座'].indexOf(a.brokerageAccount) > -1) &&
      (filterKey['通貨'].length === 0 ||
        filterKey['通貨'].indexOf(a.currency) > -1) &&
      (filterKey['資産クラス'].length === 0 ||
        filterKey['資産クラス'].indexOf(a.productType) > -1)
    );
  });
  return assets;
};
